import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaUsers, FaChalkboardTeacher, FaCogs, FaSignOutAlt, FaChevronDown, FaEye } from "react-icons/fa";
import styles from "./UsuariosGerenciar.module.css";

const UsuariosGerenciar = () => {
  const navigate = useNavigate();
  const [usuarios, setUsuarios] = useState([]);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [modalUsuario, setModalUsuario] = useState(null);

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/usuarios/ver_usuarios`); // 🔥 Ajuste para a rota correta
        setUsuarios(response.data);
      } catch (error) {
        console.error("Erro ao buscar usuários:", error);
      }
    };

    fetchUsuarios();
  }, []);

  const toggleSubmenu = (menu) => {
    setActiveSubmenu(activeSubmenu === menu ? null : menu);
  };

  const abrirModalUsuario = (usuario) => {
    setModalUsuario(usuario);
  };

  const fecharModal = () => {
    setModalUsuario(null);
  };

  const usuariosPorCargo = usuarios.reduce((acc, usuario) => {
    if (!acc[usuario.tipo]) acc[usuario.tipo] = [];
    acc[usuario.tipo].push(usuario);
    return acc;
  }, {});

  const usuariosPorStatus = usuarios.reduce((acc, usuario) => {
    if (!acc[usuario.status]) acc[usuario.status] = [];
    acc[usuario.status].push(usuario);
    return acc;
  }, {});

  return (
    <div className={styles.painelContainer}>

              {/* CABEÇALHO */}
        <header className={styles.dashboardHeader}>
        <h3>Gerenciamento de Usuários</h3>
          <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
        </header>

      <aside className={styles.sidebar}>
        <button className={styles.sidebarBtn} onClick={() => navigate("/dashboard")}>
          <FaUsers />
        </button>

        <div className={styles.sidebarItem}>
          <button className={styles.sidebarBtn} onClick={() => toggleSubmenu("usuarios")}>
            <FaUsers />
            <FaChevronDown className={styles.chevron} />
          </button>
          {activeSubmenu === "usuarios" && (
            <div className={styles.submenu}>
              <button onClick={() => navigate("/usuarios")}>Gerenciar Usuários</button>
            </div>
          )}
        </div>

        <button className={styles.sidebarBtn} onClick={() => navigate("/painel-controle")}>
          <FaChalkboardTeacher />
        </button>

        <div className={styles.bottomButtons}>
          <button className={styles.sidebarBtn} onClick={() => navigate("/configuracao")}>
            <FaCogs />
          </button>
          <button className={styles.sidebarBtn} onClick={() => navigate("/login")}>
            <FaSignOutAlt />
          </button>
        </div>
      </aside>

      <div className={styles.content}>
        <div className={styles.usuariosContainer}>
          <div className={styles.section}>
            {Object.entries(usuariosPorCargo).map(([tipo, usuarios]) => (
              <div key={tipo} className={styles.usuarioGrupo}>
                <h3>{tipo}</h3>
                <ul>
                  {usuarios.map((usuario) => (
                    <li key={usuario.id}>
                      <FaUsers />
                      {usuario.nome} - <strong>{usuario.tipo}</strong>
                      <button onClick={() => abrirModalUsuario(usuario)}>
                        <FaEye /> Ver Detalhes
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

        
        </div>
      </div>

      {modalUsuario && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <h2>{modalUsuario.nome}</h2>
            <p><strong>Matricula:</strong> {modalUsuario.matricula}</p>
            <p><strong>Cargo:</strong> {modalUsuario.cargo}</p>
            <p><strong>Email:</strong> {modalUsuario.email || "Não definido"}</p>
            <p><strong>Status:</strong> {modalUsuario.status}</p>
            <h3>Endereço</h3>
            <p>{modalUsuario.endereco || "Não informado"}</p>
            <button onClick={fecharModal}>Fechar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsuariosGerenciar;
