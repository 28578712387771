import React, { useState, useEffect } from "react";
import styles from "./Modal.module.css";
import { FaTimes, FaSave, FaBan, FaUser, FaIdCard, FaEnvelope, FaPhone, FaLock, FaCamera, FaIdBadge } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModalCriacaoUsuario = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    tipo: "funcionario",
    nome: "",
    cpf: "",
    email: "",
    telefone: "",
    senha: "",
    matricula: "",
    cargo: "funcionario",
    status: "ativo",
    siglaPartido: "",
    endereco: "",
    data_nascimento: "",
    foto: null,
    anoInicial: "",
    anoFinal: ""
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // Lista de cargos disponíveis
    setFormData((prev) => ({ ...prev, cargo: "funcionario" }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      foto: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
  
    try {
      const formDataToSend = new FormData();
      
      Object.keys(formData).forEach((key) => {
        if (formData[key]) {
          formDataToSend.append(key, formData[key]);
        }
      });
  
      console.log("📌 Dados enviados ao backend:", Object.fromEntries(formDataToSend)); // 🔥 Debug
  
      const response = await fetch(`${process.env.REACT_APP_API}/usuarios/criar`, {
        method: "POST",
        body: formDataToSend,
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Erro ao cadastrar usuário: ${errorMessage}`);
      }
  
      toast.success("Usuário criado com sucesso! 🎉");
      closeModal();
    } catch (error) {
      setError(error.message);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };  
  
  return (

    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <button className={styles.modalClose} onClick={closeModal}>
          <FaTimes />
        </button>
        <h2 className={styles.modalTitle}><FaUser /> Cadastrar Usuário</h2>

        {error && <p className={styles.errorMessage}>{error}</p>}

        <form className={styles.formUsuario} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label>Escolha o tipo de usuário</label>
            <select name="tipo" value={formData.tipo} onChange={handleChange} required>
              <option value="">Selecione...</option>
              <option value="vereador">Parlamentar</option>
              <option value="funcionario">Colaborador</option>
            </select>
          </div>

          {formData.tipo && (
            <>
            <div className={styles.row}>
             
              <div className={styles.inputGroup}>
                <label><FaUser /> Nome</label>
                <input type="text" name="nome" value={formData.nome} onChange={handleChange} required />
              </div>

              <div className={styles.inputGroup}>
                <label><FaIdCard /> CPF</label>
                <input type="text" name="cpf" value={formData.cpf} onChange={handleChange} required />
              </div>

              <div className={styles.inputGroup}>
                <label><FaIdCard /> Data de Nascimento</label>
                <input type="date" name="data_nascimento" value={formData.data_nascimento} onChange={handleChange} required />
              </div>
            
            </div>

            <div className={styles.row}>

              <div className={styles.inputGroup}>
                <label><FaIdBadge /> Matrícula</label>
                <input type="text" name="matricula" value={formData.matricula} onChange={handleChange} required />
              </div>

              <div className={styles.inputGroup}>
                <label><FaEnvelope /> Email</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
              </div>

              <div className={styles.inputGroup}>
                <label><FaPhone /> Telefone</label>
                <input type="text" name="telefone" value={formData.telefone} onChange={handleChange} />
              </div>
            
            </div>

            <div className={styles.row}>

              <div className={styles.inputGroup}>
                <label><FaLock /> Senha</label>
                <input type="password" name="senha" value={formData.senha} onChange={handleChange} required />
              </div>

              {formData.tipo === "vereador" && (
                <>
                  <div className={styles.inputGroup}>
                    <label>Sigla do Partido</label>
                    <input type="text" name="siglaPartido" value={formData.siglaPartido} onChange={handleChange} />
                  </div>
                  <div className={styles.inputGroup}>
                    <label>Ano Inicial do Mandato</label>
                    <input type="number" name="anoInicial" value={formData.anoInicial} onChange={handleChange} />
                  </div>
                  <div className={styles.inputGroup}>
                    <label>Ano Final do Mandato</label>
                    <input type="number" name="anoFinal" value={formData.anoFinal} onChange={handleChange} />
                  </div>
                </>
              )}

              <div className={styles.inputGroup}>
                <label><FaCamera /> Foto</label>
                <input type="file" name="foto" onChange={handleFileChange} />
              </div>
            
            </div>

            <div className={styles.row}>
              
              <div className={styles.modalActions}>
                <button type="submit" className={styles.saveButton} disabled={loading}>
                  {loading ? "Salvando..." : <><FaSave /> Salvar</>}
                </button>
                <button type="button" className={styles.cancelButton} onClick={closeModal}>
                  <FaBan /> Cancelar
                </button>
              </div>
            
            </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default ModalCriacaoUsuario;
