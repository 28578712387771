import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Modal.module.css";
import { FaArrowRight, FaTrash, FaPlusCircle, FaCheckCircle, FaTimes } from "react-icons/fa";

const ModalConfiguracaoSessao = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [passo, setPasso] = useState(1);
  const [tipoSessao, setTipoSessao] = useState("");
  const [dataSessao, setDataSessao] = useState("");
  const [atas, setAtas] = useState([]);
  const [ataSelecionada, setAtaSelecionada] = useState("");
  const [expediente, setExpediente] = useState([]);
  const [materiasRecebidas, setMateriasRecebidas] = useState([]);
  const [pautasVotacao, setPautasVotacao] = useState([]);
  const [ritoSessao, setRitoSessao] = useState([]);
  const [tribunaVereadores, setTribunaVereadores] = useState([]);
  const [tribunaPopulares, setTribunaPopulares] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        try {
          const [atasRes, pautasRes] = await Promise.all([
            axios.get(`${process.env.REACT_APP_API}/atas`),
            axios.get(`${process.env.REACT_APP_API}/pautas`),
          ]);
          setAtas(atasRes.data || []);
          setMateriasRecebidas(pautasRes.data.filter(p => ["Recebido", "Novo", "Encaminhado"].includes(p.status)));
          setPautasVotacao(pautasRes.data.filter(p => p.status === "Aguardando Votacao"));
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
        }
      };
      fetchData();
    }
  }, [isOpen]);

  const handleAvancar = () => setPasso(passo + 1);
  const handleVoltar = () => setPasso(passo - 1);

  const handleAdicionar = (item, setOrigem, setDestino) => {
    setDestino(prev => [...prev, item]);
    setOrigem(prev => prev.filter(i => i.id !== item.id));
  };

  const handleRemover = (item, setOrigem, setDestino) => {
    setDestino(prev => [...prev, item]);
    setOrigem(prev => prev.filter(i => i.id !== item.id));
  };

  const handleCriarSessao = async () => {
    try {
      const ritoData = [...expediente, ...ritoSessao, ...tribunaVereadores, ...tribunaPopulares].map(p => ({
        id: p.id,
        titulo: p.titulo,
        autor: p.autor || "",
        status: p.status || "",
        dataVotacao: p.dataVotacao || null,
      }));

      const response = await axios.post("${process.env.REACT_APP_API}/sessoes", {
        tipo: tipoSessao,
        data: dataSessao,
        ataId: ataSelecionada,
        rito: JSON.stringify(ritoData),
      });
      alert(`Sessão criada com sucesso! Nº ${response.data.numero}`);
      onClose();
      navigate("/dashboard");
    } catch (error) {
      console.error("Erro ao criar sessão:", error);
      alert("Erro ao criar sessão. Verifique os dados.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h1 className={styles.modalTitle}>Configuração da Sessão</h1>
          <button className={styles.closeButton} onClick={onClose}><FaTimes /></button>
        </div>
        {passo === 1 && (
          <div>
            <h2>Passo 1: Tipo e Data</h2>
            <select value={tipoSessao} onChange={(e) => setTipoSessao(e.target.value)}>
              <option value="">Selecione o tipo de sessão</option>
              <option value="Ordinária">Ordinária</option>
              <option value="Extraordinária">Extraordinária</option>
            </select>
            <input type="date" value={dataSessao} onChange={(e) => setDataSessao(e.target.value)} />
            <button onClick={handleAvancar}><FaArrowRight /> Próximo</button>
          </div>
        )}

        {passo === 4 && (
          <div>
            <h2>Passo 4: Resumo da Sessão</h2>
            <p><strong>Tipo:</strong> {tipoSessao}</p>
            <p><strong>Data:</strong> {dataSessao}</p>
            <p><strong>Ata Selecionada:</strong> {ataSelecionada}</p>
            <h3>Expediente:</h3>
            <ul>{expediente.map((p) => (<li key={p.id}>{p.titulo}</li>))}</ul>
            <h3>Tribuna dos Vereadores:</h3>
            <ul>{tribunaVereadores.map((p) => (<li key={p.id}>{p.titulo}</li>))}</ul>
            <h3>Tribuna dos Populares:</h3>
            <ul>{tribunaPopulares.map((p) => (<li key={p.id}>{p.titulo}</li>))}</ul>
            <button onClick={handleVoltar}>Voltar</button>
            <button onClick={handleCriarSessao}><FaCheckCircle /> Criar Sessão</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalConfiguracaoSessao;
