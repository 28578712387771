import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { SocketProvider } from "./contexts/SocketContext";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Config from "./pages/Config";
import Sessao from "./pages/Sessao";
import PrivateRoute from "./routes/PrivateRoute";
import PainelControle from "./pages/PainelControle";
import PainelSessao from "./pages/PainelSessao";
import ConfiguracaoSessao from "./pages/ConfiguracaoSessao";
import Comissoes from "./pages/Comissoes";
import ComissaoDetalhes from "./pages/ComissaoDetalhes";
import UsuariosGerenciar from "./pages/UsuariosGerenciar";
import PautasGerenciar from './pages/PautasGerenciar';
import AtasGerenciar from './pages/AtasGerenciar';
import SessoesDetalhes from "./pages/SessaoDetalhes";
import CamaraMunicipal from "./pages/CamaraMunicipal";

import { isTokenValid, logout } from "./utils/auth"; // 👈 aqui usamos a verificação

const ADMIN_PRESIDENTE = ["admin", "presidente", "vereador", "secretario"];
const VEREADOR_SECRETARIO = ["vereador", "secretario"];
const TODOS_USUARIOS = ["admin", "presidente", "vereador"];

function AppWrapper() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decoded = JSON.parse(atob(token.split('.')[1]));
        const now = Date.now() / 1000;
        const timeLeft = (decoded.exp - now) * 1000;

        if (timeLeft > 0) {
          setTimeout(() => {
            logout();
            navigate("/login");
          }, timeLeft);
        } else {
          logout();
          navigate("/login");
        }
      } catch {
        logout();
        navigate("/login");
      }
    }
  }, [navigate]);

  return (
    <SocketProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route element={<PrivateRoute rolesAllowed={ADMIN_PRESIDENTE} />}>
            <Route path="/configuracao" element={<Config />} />
            <Route path="/configuracao/sessao" element={<ConfiguracaoSessao />} />
            <Route path="/painel-controle" element={<PainelControle />} />
            <Route path="/pautas-gerenciar" element={<PautasGerenciar />} />
            <Route path="/atas-gerenciar" element={<AtasGerenciar />} />
            <Route path="/sessoes-anteriores" element={<SessoesDetalhes />} />
          </Route>

          <Route element={<PrivateRoute rolesAllowed={["secretario", "presidente"]} />}>
            <Route path="/sessao/:id" element={<Sessao />} />
          </Route>

          <Route path="/painelsessao" element={<PainelSessao />} />

          <Route element={<PrivateRoute rolesAllowed={TODOS_USUARIOS} />}>
            <Route path="/comissoes" element={<Comissoes />} />
            <Route path="/comissoes/:id" element={<ComissaoDetalhes />} />
            <Route path="/usuarios" element={<UsuariosGerenciar />} />
          </Route>

          <Route path="/camara-municipal" element={<CamaraMunicipal />} />

          <Route path="*" element={<Login />} />
        </Routes>
    </SocketProvider>
  );
}

export default AppWrapper;
