  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import styles from "./Styles.module.css";
  import { FaUser, FaBook, FaArchive, FaChalkboardTeacher, FaTrash, FaPlusCircle, FaCalendarAlt, FaCogs, FaHome,
  FaScroll, FaInbox, FaMicrophone, FaCalendarPlus, FaFileAlt, FaSignOutAlt } from "react-icons/fa";
  import { DndContext, closestCenter } from "@dnd-kit/core";
  import { SortableContext, arrayMove, useSortable } from "@dnd-kit/sortable";
  import { CSS } from "@dnd-kit/utilities";

  // Componente de item arrastável
  const SortableItem = ({ id, titulo, cargo, autor, siglaPartido, minuta }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      padding: "10px",
      border: "1px solid #ddd",
      marginBottom: "5px",
      backgroundColor: "white",
      cursor: "grab",
      borderRadius: "5px",
    };

    return (
      <li ref={setNodeRef} style={style} {...attributes} {...listeners}>
          <strong>{titulo}</strong>
          {minuta && <p style={{ margin: 0, fontSize: "0.9em" }}>Minuta: {minuta}</p>}
          {autor && <p style={{ margin: 0, fontSize: "0.9em" }}>Autor: {autor}</p>}
          {cargo && <p style={{ margin: 0, fontSize: "0.9em" }}>Cargo: {cargo}</p>}
          {siglaPartido && <p style={{ margin: 0, fontSize: "0.9em",  fontWeight: "bold" }}>{siglaPartido}</p>}
      </li>
    );
  };

  const ConfiguracaoSessao = () => {
    const navigate = useNavigate();
    const [pautas, setPautas] = useState([]);
    const [vereadores, setVereadores] = useState([]);
    const [atas, setAtas] = useState([]);
    const [dataSessao, setDataSessao] = useState("");
    const [ataSelecionada, setAtaSelecionada] = useState("");
    const [textoAdicional, setTextoAdicional] = useState("");
    const [sessaoCriada, setSessaoCriada] = useState(false);
    const [ritoSessao, setRitoSessao] = useState([]);
    const [pautasSelecionadas, setPautasSelecionadas] = useState([]);
    const [vereadoresFala, setVereadoresFala] = useState([]);
    const [idSessao, setIdSessao] = useState(null); // Corrigindo erro
    const [sessao, setSessao] = useState(null); // Criando estado da sessão

    const handleLogout = () => {
      localStorage.removeItem("token"); // Remove token de autenticação
      navigate("/login"); // Redireciona para a página de login
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const [pautasRes, vereadoresRes, atasRes] = await Promise.all([
            axios.get("${process.env.REACT_APP_API}/pautas"),
            axios.get("${process.env.REACT_APP_API}/usuarios/vereadores"),
            axios.get("${process.env.REACT_APP_API}/atas"),
          ]);

          setPautas(pautasRes.data || []);
          setVereadores(vereadoresRes.data || []);
          setAtas(atasRes.data || []);
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
        }
      };
      fetchData();
    }, []);

    useEffect(() => {
      axios.get("${process.env.REACT_APP_API}/sessoes/ultima")
        .then((res) => {
          setSessao(res.data);
          try {
            setRitoSessao(res.data.rito ? JSON.parse(res.data.rito) : []);
          } catch (error) {
            console.error("Erro ao processar o rito da sessão:", error);
            setRitoSessao([]);
          }
        })
        .catch((error) => console.error("Erro ao buscar sessão:", error));
    }, []);
    
    
    const handleDragEnd = (event) => {
      const { active, over } = event;
      if (!over) return; // Se o item não foi solto em lugar válido, sair
    
      const activeId = active.id;
      const overId = over.id;
    
      const itemPauta = pautas.find((p) => p.id === activeId);
      const itemRito = ritoSessao.find((r) => r.id === activeId);
      const itemVereador = vereadores.find((v) => v.id === activeId);
    
      // 🟢 Se for uma pauta sendo movida para o ritoSessao
      if (itemPauta) {
        setPautas((prev) => prev.filter((p) => p.id !== activeId)); // Remove da lista de pautas
        // Copia todos os dados da pauta para exibir mais informações (supondo que itemPauta já tenha os dados completos)
        setRitoSessao((prev) => [...prev, { ...itemPauta }]);
        return;
      }
    
      // 🟢 Se for um vereador sendo movido para o ritoSessao
      if (itemVereador) {
        setVereadores((prev) => prev.filter((v) => v.id !== activeId)); // Remove da lista de vereadores
        // Adiciona ao rito com os dados completos desejados
        setRitoSessao((prev) => [
          ...prev,
          {
            id: itemVereador.id,
            titulo: itemVereador.nome,
            cargo: itemVereador.cargo,
            siglaPartido: itemVereador.siglaPartido,
            foto: itemVereador.foto,
            // adicione outros campos se necessário
          },
        ]);
        return;
      }
    
      // 🔹 Se o item já está no ritoSessao, apenas reordenar
      if (itemRito) {
        const oldIndex = ritoSessao.findIndex((item) => item.id === activeId);
        const newIndex = ritoSessao.findIndex((item) => item.id === overId);
        setRitoSessao((prev) => arrayMove(prev, oldIndex, newIndex));
      }
    };
    
    const handleRemoverItem = (id) => {
      setRitoSessao((prev) => {
        const itemRemovido = prev.find((item) => item.id === id);
        if (!itemRemovido) return prev; // Caso não encontre o item, não faz nada
    
        // 🟢 Se for uma pauta, retorna para a lista de pautas
        if (itemRemovido.autor) {
          setPautas((prevPautas) => [...prevPautas, itemRemovido]);
        }
    
        // 🔵 Se for um vereador, retorna para a lista de vereadores
        if (itemRemovido.cargo) {
          setVereadores((prevVereadores) => [...prevVereadores, itemRemovido]);
        }
    
        // 🔴 Remove do rito da sessão
        return prev.filter((item) => item.id !== id);
      });
    };  
    
  const handleCriarSessao = async () => {
    if (!dataSessao || !ataSelecionada) {
      alert("Preencha todos os campos antes de criar a sessão.");
      return;
    }

    try {
      const response = await axios.post("${process.env.REACT_APP_API}/sessoes", {
        data: dataSessao,
        dataAgendada: dataSessao, // Garante que `dataAgendada` seja enviado
        ataId: ataSelecionada,
        vereadores: vereadoresFala.map((v) => v.id),
        pautas: pautasSelecionadas.map((p) => p.id),
        rito: ritoSessao,
        textoAdicional,
      });

      setIdSessao(response.data.id);
      setSessao(response.data);
      setSessaoCriada(true);

      alert(`Sessão ${response.data.numero} criada com sucesso!`);
    } catch (error) {
      console.error("Erro ao criar sessão:", error);
      alert("Erro ao criar sessão. Verifique os dados e tente novamente.");
    }
  };

    return (
      <div className={styles.container}>
      <aside className={styles.sidebar}>
        {/* Página Inicial */}
        <button className={styles.sidebarBtn} title="Página Inicial" onClick={() => navigate("/dashboard")}>
          <FaHome />
        </button>

        {/* Nova Sessão */}
        <button className={styles.sidebarBtn} title="Nova Sessão" onClick={() => navigate("/configuracao/sessao")}>
          <FaPlusCircle />
        </button>

        {/* Controle da Sessão */}
        <button className={styles.sidebarBtn} title="Controle da Sessão" onClick={() => navigate("/painel-controle")}>
          <FaChalkboardTeacher />
        </button>

        {/* Sessões Arquivadas */}
        <button className={styles.sidebarBtn} title="Sessões Arquivadas" onClick={() => navigate("/configuracao/sessao/arquivadas")}>
          <FaArchive />
        </button>

        {/* Configuração e Logout alinhados na base */}
        <div className={styles.bottomButtons}>
          <button className={styles.sidebarBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
            <FaCogs />
          </button>

          <button className={styles.sidebarBtn} title="Logout" onClick={handleLogout}>
            <FaSignOutAlt />
          </button>
        </div>
      </aside>

        <main className={styles.content}>
          {/* CABEÇALHO */}
          
          <header className={styles.header}>
            <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
            <h1>Configuração | Sessão</h1>
          </header>

          <section className={styles.sessaoConfig}>
          <button className={styles.agendaBtn}>
    <FaCalendarAlt className={styles.icon} />
    Agendamento
  </button>

            <input type="date" value={dataSessao} onChange={(e) => setDataSessao(e.target.value)} />
            <select value={ataSelecionada} onChange={(e) => setAtaSelecionada(e.target.value)}>
              <option value="">Vincular a Ata</option>
              {atas.map((ata) => (
                <option key={ata.id} value={ata.id}>{ata.conteudo}-{ata.dataCriacao}</option>
              ))}
            </select>
            <button className={styles.criarSessaoBtn} onClick={handleCriarSessao} disabled={sessaoCriada}>
              <FaPlusCircle className={styles.icon} />
              {sessaoCriada ? " Sessão Criada" : " Criar"}
            </button>
          </section>

          <div className={styles.dragDropContainer}>
    {/* 🔹 Coluna A - Rito da Sessão */}
    <section className={styles.colunaA}>
    <h2><FaScroll className={styles.iconTitle} /> Configuração do Rito da Sessão</h2>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={ritoSessao.map((r) => r.id)}>
        <ul>
    {ritoSessao.map((r) => (
      <li key={r.id} className={styles.item}>
        <span>{r.titulo} - {r.minuta}{r.autor || r.cargo}</span>
        <button onClick={() => handleRemoverItem(r.id)} className={styles.btnRemover}>
          <FaTrash />
        </button>
      </li>
    ))}
  </ul>
        </SortableContext>
      </DndContext>
    </section>

    {/* 🔹 Coluna B - Proposições */}
    <section className={styles.colunaB}>
    <h2><FaInbox className={styles.iconTitle} /> Proposições</h2>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={pautas.map((p) => p.id)}>
          <ul>
            {pautas.map((p) => (
              <SortableItem key={p.id} id={p.id} titulo={p.titulo} minuta={p.minuta} autor={p.autor} />
            ))}
          </ul>
        </SortableContext>
      </DndContext>
    </section>

    {/* 🔹 Coluna C - Parlamentares */}
    <section className={styles.colunaC}>
    <h3><FaMicrophone className={styles.iconTitle} /> Parlamentares</h3>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={vereadores.map((v) => v.id)}>
          <ul>
            {vereadores.map((v) => (
              <SortableItem key={v.id} id={v.id} titulo={v.nome} cargo={v.cargo} siglaPartido={v.siglaPartido} />
            ))}
          </ul>
        </SortableContext>
      </DndContext>
    </section>
  </div>


        </main>
      </div>
    );
  };

  export default ConfiguracaoSessao;
