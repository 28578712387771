import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./PainelSessao.module.css";

const PainelSessao = () => {
  const [votos, setVotos] = useState([]);
  const [sessao, setSessao] = useState(null);
  const [painelItens, setPainelItens] = useState([]);
  const [votacaoAtiva, setVotacaoAtiva] = useState(null);
  const [totalVereadores, setTotalVereadores] = useState(0);
  const [tempoSessao, setTempoSessao] = useState(0);
  const [minuta, setMinuta] = useState("");
  const [votacoesAtivas, setVotacoesAtivas] = useState([]);
  const [votacaoAtualIndex, setVotacaoAtualIndex] = useState(0);
  const [votacaoAtual, setVotacaoAtual] = useState(null);
  const [inicioFala, setInicioFala] = useState({});
  const [statusAnterior, setStatusAnterior] = useState({});
  const [temposVereadores, setTemposVereadores] = useState({});
  
  useEffect(() => {
    const novosTempos = { ...inicioFala };
    const novosStatus = { ...statusAnterior };
  
    painelItens.forEach(item => {
      const estaExibindo = item.status === "exibindo" && item.tipo.toLowerCase() === "discursante";
      const estavaExibindo = statusAnterior[item.id] === "exibindo";
  
      if (estaExibindo && !estavaExibindo) {
        const agora = Date.now();
        novosTempos[item.id] = agora;
      
        // Salva a data de início da fala no banco
        const dataHoraISO = new Date(Date.now()).toISOString();
        atualizarTempoNoBanco(item.id, item.tempoUsado || 0, dataHoraISO);
      }
      
      // 👉 Se parou de falar agora
      if (!estaExibindo && estavaExibindo) {
        if (inicioFala[item.id]) {
          const tempoUsado = Math.floor((Date.now() - inicioFala[item.id]) / 1000);
          atualizarTempoNoBanco(item.id, tempoUsado);
          delete novosTempos[item.id];
        }
      }
  
      // Atualiza status atual
      novosStatus[item.id] = item.status;
    });
  
    setInicioFala(novosTempos);
    setStatusAnterior(novosStatus);
  }, [painelItens]);
  
  const atualizarTempoNoBanco = async (itemId, tempoUsado, dataHoraInicioFala = null) => {
    try {
      console.log("⏱️ PATCH:", { tempoUsado, dataHoraInicioFala });
      await axios.patch(`${process.env.REACT_APP_API}/painel-sessao/${itemId}`, {
        tempoUsado,
        dataHoraInicioFala,
      });
    } catch (error) {
      console.error("Erro ao atualizar tempo no banco:", error);
    }
  };  
  
  const buscarVotos = async (sessaoId) => {
    if (!sessaoId) return;

    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/voto/sessao?sessaoId=${sessaoId}`);
      console.log("📌 Votos recebidos para a sessão:", sessaoId, response.data);

      if (response.data.length > 0) {
        setVotos(response.data);  // Atualiza apenas se houver votos
      }
    } catch (error) {
      console.error("Erro ao buscar votos:", error);
    }
  };

  useEffect(() => {
    if (sessao?.id) {
      buscarVotos(sessao.id);
    }
  }, [sessao?.id]);

 
  useEffect(() => {
    const buscarVotacaoAtiva = async () => {
      try {
        const { data: votacoes } = await axios.get(`${process.env.REACT_APP_API}/votacoes`);
        const votacoesFiltradas = votacoes.filter(vot => vot.sessaoId === sessao?.id && vot.status === "andamento");
        setVotacoesAtivas(votacoesFiltradas);

        if (votacoesFiltradas.length > 0) {
          setVotacaoAtual(votacoesFiltradas[0]);
          buscarVotos(sessao?.id);
        } else {
          setVotacaoAtual(null);
          setVotos([]);
        }
      } catch (error) {
        console.error("Erro ao buscar votações ativas:", error);
      }
    };

    buscarVotacaoAtiva();
    const interval = setInterval(buscarVotacaoAtiva, 5000);
    return () => clearInterval(interval);
  }, [sessao]);

  useEffect(() => {
    if (votacoesAtivas.length > 1) {
      const interval = setInterval(() => {
        setVotacaoAtualIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % votacoesAtivas.length;
          setVotacaoAtual(votacoesAtivas[newIndex]);
          buscarVotos(sessao?.id);
          return newIndex;
        });
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [votacoesAtivas]);

  /**
   * 🔍 Busca inicial de dados
   */
  useEffect(() => {
    const fetchDados = async () => {
      try {
        const [sessaoRes, painelRes, pautasRes, votacaoRes, camaraRes, votosRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`),
          axios.get(`${process.env.REACT_APP_API}/painel-sessao`),
          axios.get(`${process.env.REACT_APP_API}/pautas`),
          axios.get(`${process.env.REACT_APP_API}/votacoes`),
          axios.get(`${process.env.REACT_APP_API}/camara-municipal`),
          axios.get(`${process.env.REACT_APP_API}/voto/sessao?sessaoId=80`), // Rota correta para votos
        ]);

        setSessao(sessaoRes.data);
        setTotalVereadores(camaraRes.data.totalVereadores || 0);
        setPainelItens(painelRes.data.filter(item => Number(item.sessaoId) === Number(sessaoRes.data.id)));

        if (sessaoRes.data.status === "aberta" && sessaoRes.data.inicio) {
          setTempoSessao(Math.floor((Date.now() - new Date(sessaoRes.data.inicio).getTime()) / 1000));
        }

        const votacaoAtiva = votacaoRes.data.find(votacao => votacao.sessaoId === sessaoRes.data.id && votacao.status === "andamento");
        setVotacaoAtiva(votacaoAtiva || null);

        if (votacaoAtiva) {
          const votosFiltrados = votosRes.data.filter(voto => voto.votacao.id === votacaoAtiva.id);
          setVotos(votosFiltrados);

          const pautaEncontrada = pautasRes.data.find(pauta => pauta.id === votacaoAtiva.pautaId);
          setMinuta(pautaEncontrada?.minuta || "Minuta não disponível");
        } else {
          setVotos([]);
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchDados();
    const interval = setInterval(fetchDados, 1000);
    return () => clearInterval(interval);
  }, []);

  
 // Filtra somente os itens cujo status seja "exibindo"
 const itensExibidos = painelItens.filter(item => item.status === "exibindo" || item.status === "votacao");

 // Dentro dos itens exibidos, separa pautas e vereadores:
 const pautasExibidas = itensExibidos.filter(item => 
   item.autor && item.autor.trim() !== "" && (!item.cargo || item.cargo.toLowerCase() !== "discursante")
 );
 const vereadoresExibidos = itensExibidos.filter(item => 
   item.cargo && item.cargo.toLowerCase() === "discursante"
 );

 const tempoTotalUsado = vereadoresExibidos[0]?.id && inicioFala[vereadoresExibidos[0].id]
 ? Math.floor((Date.now() - inicioFala[vereadoresExibidos[0].id]) / 1000) +
   (vereadoresExibidos[0].tempoUsado || 0)
 : vereadoresExibidos[0]?.tempoUsado || 0;


   useEffect(() => {
     // Inicia o contador para cada vereador exibido
     const interval = setInterval(() => {
       setTemposVereadores(prevTempos => {
         const novosTempos = { ...prevTempos };
 
         vereadoresExibidos.forEach(vereador => {
           if (!novosTempos[vereador.id]) {
             novosTempos[vereador.id] = 900; // 15 minutos em segundos
           } else if (novosTempos[vereador.id] > 0) {
             novosTempos[vereador.id] -= 1; // Decrementa o tempo
           }
         });
 
         return novosTempos;
       });
     }, 1000); // Atualiza a cada 1 segundo
 
     return () => clearInterval(interval); // Limpa o intervalo quando o componente desmontar
   }, [vereadoresExibidos]);
 
  const formatarTempo = (segundos) => {
    const horas = String(Math.floor(segundos / 3600)).padStart(2, "0");
    const minutos = String(Math.floor((segundos % 3600) / 60)).padStart(2, "0");
    const seg = String(segundos % 60).padStart(2, "0");
    return `${horas}:${minutos}:${seg}`;
  };

  const formatarData = (dataISO) => {
    const data = new Date(dataISO);
    return data.toLocaleDateString("pt-BR", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  const calcularResultado = () => {
    if (!votacaoAtiva || !sessao) return null;
  
    const votosValidos = votos.filter(v => v.sessao && v.sessao.id === sessao?.id);
    const votosSim = votosValidos.filter(v => v.escolha === "sim").length;
    const votosNao = votosValidos.filter(v => v.escolha === "nao").length;
    const votosAbstencao = votosValidos.filter(v => v.escolha === "abstencao").length;     
  
    console.log("📌 Votos SIM:", votosSim);
    console.log("📌 Votos NÃO:", votosNao);
    console.log("📌 Votos ABSTENÇÃO:", votosAbstencao);
  
    const totalNecessario = votacaoAtiva.tipoVotacao === "Maioria Simples"
      ? Math.ceil(totalVereadores / 2) + 1
      : votacaoAtiva.tipoVotacao === "Maioria Absoluta"
        ? Math.ceil(totalVereadores / 2)
        : Math.ceil((2 / 3) * totalVereadores);
  
    if (votosValidos.length >= totalNecessario) {
      return votosSim >= totalNecessario ? "APROVADO" : "REPROVADO";
    }
    return null;
  };  
  
  return (
    <div className={styles.painelSessaoContainer}>
       <div className={styles.painelSessaoContainer}>

<div className={styles.dashboardContent}>
<header className={styles.headerSessao}>
        <h2>{sessao ? `Sessão ${sessao.tipo} nº ${sessao.numero}` : "Nenhuma sessão ativa"} - {sessao ? formatarData(sessao.data) : "Data não disponível"}</h2>
          <div className={styles.cronometroSessao}>{formatarTempo(tempoSessao)}</div>
        </header>
        
      <main className={styles.painelPrincipal}>

  
        <section className={styles.discussao}>
          <h3>Em discussão </h3>
          {pautasExibidas.length > 0 ? (
            pautasExibidas.map(item => (
              <div key={item.id}>
                <strong className={styles.backgroundTitulo}>{item.titulo}</strong>
                <p className={styles.backgroundTitulo}>Autoria: {item.autor}</p>
                <strong className={styles.backgroundTitulo}>Minuta:</strong>
                <p className={styles.backgroundTitulo}>{item.minuta}</p>
              </div>
            ))
          ) : (
            <p style={{ color: "red" }}>Nenhuma pauta encontrada.</p>
          )}
        </section>
  
        {vereadoresExibidos.length > 0 && (
  <section className={styles.vereadorEmFala}>
    <h3>Na tribuna </h3>
    <div className={styles.vereadorDestaque}>
      <img 
        src={`${process.env.REACT_APP_API}/images/vereadores/${vereadoresExibidos[0].id}_${vereadoresExibidos[0].titulo.replace(/\s+/g, '')}.png`}
        alt={`Foto de ${vereadoresExibidos[0].titulo}`}
        onError={(e) => (e.target.src = "/images/placeholder.png")}
      />
      
      <div className={styles.pautaGrupo}>
      <h2>{vereadoresExibidos[0].titulo}</h2>
      <img 
        className="partidoImg"
        src={`${process.env.REACT_APP_API}/images/partidos/${vereadoresExibidos[0].siglaPartido}.png`}
        alt={`Partido ${vereadoresExibidos[0].siglaPartido}`}
        onError={(e) => (e.target.src = "/images/partido.png")}
      />

</div>
   <div className={styles.timerDisplay}>
  {vereadoresExibidos[0] ? (
    formatarTempo(
      (inicioFala[vereadoresExibidos[0].id]
        ? Math.floor((Date.now() - inicioFala[vereadoresExibidos[0].id]) / 1000)
        : 0
      ) + (vereadoresExibidos[0].tempoUsado || 0)
    )
  ) : (
    "00:00:00"
  )}

</div>
    </div>
  </section>
)}


      </main>
      </div>
      </div>
    </div>
  );
  
};

export default PainelSessao;
