import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./Modal.module.css";
import {
  FaTimes, FaFileAlt, FaAlignLeft, FaUpload, FaSave, FaBan, FaCalendarAlt,
  FaUser, FaUsers, FaMicrophone, FaClipboardList
} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModalCriarAta = ({ show, handleClose }) => {
  const [anexo, setAnexo] = useState(null);
  const [dadosSessao, setDadosSessao] = useState(null);
  const [ordemDoDia, setOrdemDoDia] = useState([]);
  const [expediente, setExpediente] = useState([]);
  const [tribuna, setTribuna] = useState([]);
  const [vereadores, setVereadores] = useState([]);
  const [presidente, setPresidente] = useState("");
  const [secretario, setSecretario] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const buscarSessaoEncerrada = async () => {
      try {
        const { data: sessao } = await axios.get(`${process.env.REACT_APP_API}/sessoes/encerrada`);

        if (!sessao || !sessao.id) {
          toast.warn("⚠️ Nenhuma sessão encerrada encontrada.");
          return;
        }

        setDadosSessao(sessao);

        // Buscar Ordem do Dia, Expediente, Tribuna e Vereadores Presentes
        const [ordemRes, expedienteRes, tribunaRes, vereadoresRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API}/sessao-ordem/${sessao.id}`),
          axios.get(`${process.env.REACT_APP_API}/sessao-expediente/${sessao.id}`),
          axios.get(`${process.env.REACT_APP_API}/sessao-tribuna/${sessao.id}`),
          axios.get(`${process.env.REACT_APP_API}/presencas/sessao/${sessao.id}`)
        ]);

        setOrdemDoDia(ordemRes.data || []);
        setExpediente(expedienteRes.data || []);
        setTribuna(tribunaRes.data || []);
        setVereadores(vereadoresRes.data.map(presenca => presenca.usuario));

      } catch (error) {
        console.error("Erro ao buscar a sessão encerrada:", error);
        toast.error("Erro ao carregar sessão encerrada.");
      }
    };

    if (show) {
      buscarSessaoEncerrada();
    }
  }, [show]);

  const handleFileChange = (event) => {
    setAnexo(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    if (!dadosSessao) {
      toast.error("Nenhuma sessão encontrada para vincular a ata.");
      setLoading(false);
      return;
    }
  
    if (!presidente || !secretario) {
      toast.warn("⚠️ Selecione o presidente e o secretário antes de salvar.");
      setLoading(false);
      return;
    }

  
    const ataData = {
      sessaoId: dadosSessao.id,
      presidente,
      secretario,
      ordem_dia: JSON.stringify(ordemDoDia.map(item => item.titulo)),
      expediente: JSON.stringify(expediente.map(item => item.titulo)),
      tribuna: JSON.stringify(tribuna.map(item => ({ nome: item.nome, tema: item.tema }))),
      anexo: anexo || null
    };
  
    console.log("📤 Enviando dados da ata:", ataData);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/atas`, ataData, {
        headers: { "Content-Type": "application/json" },
      });
  
      console.log("Resposta do backend:", response.data);
      toast.success("Ata criada com sucesso!");
      handleClose();
    } catch (error) {
      console.error("Erro ao criar ata:", error.response ? error.response.data : error);
      toast.error(error.response?.data?.message || "Erro ao criar ata.");
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <button className={styles.modalClose} onClick={handleClose}>
          <FaTimes />
        </button>

        <h2 className={styles.modalTitle}>
          <FaFileAlt /> Criar Ata da Sessão Encerrada
        </h2>

        <form className={styles.formPautaAdd} onSubmit={handleSubmit}>
          {dadosSessao ? (
            <>
              <div className={styles.infoSessao}>
                <p><FaCalendarAlt /> <strong>Sessão:</strong> {dadosSessao.numero} - {dadosSessao.data}</p>
              </div>

              <div className={styles.row}>
                <div className={styles.inputGroup}>
                  <label><FaUser /> Presidente</label>
                  <select value={presidente} onChange={(e) => setPresidente(e.target.value)}>
                    <option value="">Selecione o Presidente</option>
                    {vereadores.map((vereador) => (
                      <option key={vereador.id} value={vereador.nome}>{vereador.nome}</option>
                    ))}
                  </select>
                </div>

                <div className={styles.inputGroup}>
                  <label><FaUsers /> Secretário</label>
                  <select value={secretario} onChange={(e) => setSecretario(e.target.value)}>
                    <option value="">Selecione o Secretário</option>
                    {vereadores.map((vereador) => (
                      <option key={vereador.id} value={vereador.nome}>{vereador.nome}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.modalSection}>
                <label><FaClipboardList /> Ordem do Dia</label>
                <ul>
                  {ordemDoDia.map((item, index) => <li key={index}>{item.titulo}</li>)}
                </ul>
                </div>
              </div>

              <div className={styles.row}>
              <div className={styles.modalSection}>
                <label><FaAlignLeft /> Expediente</label>
                <ul>
                  {expediente.map((item, index) => <li key={index}>{item.titulo}</li>)}
                </ul>
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.modalSection}>
                <label><FaMicrophone /> Tribuna</label>
                <ul>
                  {tribuna.map((item, index) => <li key={index}>{item.nome} - {item.tema}</li>)}
                </ul>
                </div>
              </div>

              <div className={styles.row}>
                <label className={styles.modalSection}>
                  <FaUpload /> Anexar Arquivo (PDF)
                  <input type="file" accept=".pdf" onChange={handleFileChange} hidden />
                </label>
              </div>

              <div className={styles.modalActions}>
                <button className={styles.btnAvancar} type="submit" disabled={loading}>
                  {loading ? "Salvando..." : <><FaSave /> Salvar</>}
                </button>
                <button  className={styles.btnCancelar} type="button" onClick={handleClose}><FaBan /> Cancelar</button>
              </div>
            </>
          ) : (
            <p>Carregando informações da sessão...</p>
          )}
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ModalCriarAta;
