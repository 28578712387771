import styles from "./Login.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Login() {
  const [matricula, setMatricula] = useState("");
  const [senha, setSenha] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const payload = { matricula, senha };
    console.log("🔐 Tentando login com payload:", payload);

    try {
      const url = `${process.env.REACT_APP_API}/auth/login`;
      console.log("🌐 URL da API:", url);

      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      console.log("📥 Resposta da API:", data);

      if (response.ok) {
        const token = data.access_token;
        const usuario = data.usuario;


        if (!token || !usuario) {
          console.error("❌ Token ou usuário ausente:", { token, usuario });
          toast.error("Erro inesperado: dados ausentes na resposta.");
          return;
        }

        console.log("✅ Login bem-sucedido, salvando no localStorage...");
        localStorage.setItem("token", token);
        localStorage.setItem("usuarioId", usuario.id);
        localStorage.setItem("usuarioNome", usuario.nome);
        localStorage.setItem("role", usuario.role); // Ex: presidente
        localStorage.setItem("tipo", usuario.tipo);  // Ex: admin

        console.log("🧠 Dados salvos no localStorage:", {
          token,
          usuarioId: usuario.id,
          usuarioNome: usuario.nome,
          role: usuario.cargo,
          tipo: usuario.tipo,
        });

        toast.success("Login realizado com sucesso!");
        setTimeout(() => navigate("/dashboard"), 1000);
      } else {
        console.warn("⚠️ Erro na resposta da API:", data.message);
        toast.error(data.message || "Credenciais inválidas.");
      }
    } catch (error) {
      console.error("🚨 Erro ao conectar com o servidor:", error);
      toast.error("Erro ao conectar com o servidor.");
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginPlay}>
        <div className={styles.logoContainer}>
          <img src="/logo.png" alt="Logotipo" className={styles.logo} />

          <form className={styles.formLogin} onSubmit={handleLogin}>
            <h4>Login</h4>

            <input
              type="text"
              placeholder="Matrícula"
              value={matricula}
              onChange={(e) => setMatricula(e.target.value)}
              required
              className={styles.input}
            />
            <input
              type="password"
              placeholder="Senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              required
              className={styles.input}
            />
            <button className={styles.btnEntrar} type="submit">
              Entrar
            </button>
          </form>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
}
