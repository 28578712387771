import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Modal.module.css";
import { FaTimes, FaPaperclip, FaFileAlt, FaVoteYea } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModalPauta = ({ pautaId, onClose }) => {
  const [pauta, setPauta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessaoAtual, setSessaoAtual] = useState(null);
  const [modalConfirmacao, setModalConfirmacao] = useState(null);

  useEffect(() => {
    const fetchDados = async () => {
      try {
        const responsePauta = await axios.get(`${process.env.REACT_APP_API}/pautas/${pautaId}`);
        setPauta(responsePauta.data);

        const responseSessao = await axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`);
        setSessaoAtual(responseSessao.data);

        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        toast.error("Erro ao carregar dados.");
        setLoading(false);
      }
    };

    if (pautaId) {
      fetchDados();
    }
  }, [pautaId]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") onClose();
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [onClose]);

  if (!pautaId) return null;
  if (loading) return <div className={styles.loading}>Carregando...</div>;

  const enviarParaOrdem = async (pautaId) => {
    if (!sessaoAtual) {
      toast.error("Nenhuma sessão atual encontrada!");
      return;
    }
    setModalConfirmacao({ pautaId, acao: "ordem" });
  };

  const enviarParaOrdemConfirmado = async (pautaId) => {
    try {
      toast.loading("📨 Enviando matéria para Ordem do Dia...");
      await axios.post(`${process.env.REACT_APP_API}/pautas/${pautaId}/enviar-para-ordem/${sessaoAtual.id}`);
      toast.dismiss();
      toast.success("Matéria adicionada à Ordem do Dia!");
    } catch (error) {
      toast.dismiss();
      console.error("Erro ao enviar para Ordem do Dia:", error);
      toast.error("Erro ao enviar para Ordem do Dia!");
    }
  };

  const enviarParaExpediente = async (pautaId, tipo) => {
    if (!sessaoAtual) {
      toast.error("Nenhuma sessão atual encontrada!");
      return;
    }
    setModalConfirmacao({ pautaId, tipo, acao: "expediente" });
  };

  const enviarParaExpedienteConfirmado = async (pautaId, tipo) => {
    try {
      toast.loading("📨 Enviando matéria para Expediente...");
      await axios.post(`${process.env.REACT_APP_API}/pautas/${pautaId}/enviar-para-expediente/${sessaoAtual.id}`, { tipo });
      toast.dismiss();
      toast.success("Matéria adicionada ao Expediente!");
    } catch (error) {
      toast.dismiss();
      console.error("Erro ao enviar para Expediente:", error);
      toast.error("Erro ao enviar para Expediente!");
    }
  };

  const confirmarAcao = async () => {
    if (!modalConfirmacao) return;
    const { pautaId, tipo, acao } = modalConfirmacao;
    setModalConfirmacao(null);

    if (acao === "ordem") {
      await enviarParaOrdemConfirmado(pautaId);
    } else if (acao === "expediente") {
      await enviarParaExpedienteConfirmado(pautaId, tipo);
    }
  };

  const cancelarAcao = () => {
    setModalConfirmacao(null);
  };

  return (
 <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <button className={styles.modalClose} onClick={onClose}>
          <FaTimes />
        </button>

        <div className={styles.section}>
          <h2 className={styles.modalTitle}><FaFileAlt /> Tramitação: {pauta.status}</h2>

          {/* Detalhes da Pauta */}
          <div className={styles.pautaGrupo}>
            <h3>{pauta.tipo} - {pauta.titulo} </h3> <span>Autoria:</span> {pauta.autor}
            
          </div>

          {/* Minuta e Texto Completo */}
          <div className={styles.pautaGrupo}>
            <h3>📝 Minuta</h3>
            <p className={styles.textBlock}>{pauta.minuta || "Não informada"}</p>
          </div>

          <div className={styles.pautaGrupo}>
            <h3>📖 Texto Completo</h3>
            <p className={styles.textBlock}>{pauta.texto_completo || "Não informado"}</p>
          </div>

{/* Anexo da Matéria */}
{pauta.anexo && (
  <div className={styles.pautaGrupo}>
    <h3>📎 Anexo</h3>
    <a
      href={pauta.anexo}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.attachmentLink}
    >
      <FaPaperclip /> Baixar Anexo
    </a>
  </div>
)}

{/* Anexo do Parecer */}
{pauta.parecer && (
  <div className={styles.pautaGrupo}>
    <h3>📌 Parecer</h3>
    <a
      href={pauta.parecer}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.attachmentLink}
    >
      <FaPaperclip /> Baixar Parecer
    </a>
  </div>
)}

    {/* Seção de Inclusão na Sessão (somente se a sessão estiver ativa) */}
{sessaoAtual && sessaoAtual.status !== "encerrada" && (
  <div className={styles.pautaGrupo}>
    <h3>📝 Sessão nº {sessaoAtual.numero}</h3>
    <div className={styles.modalActions}>
      <button onClick={() => enviarParaExpediente(pauta.id, "leitura_materia")}>
        <FaFileAlt /> Incluir no Expediente
      </button>
      <button onClick={() => enviarParaOrdem(pauta.id)}>
        <FaVoteYea /> Incluir na Ordem do Dia
      </button>
    </div>
  </div>
)}

          {/* Botão de Fechar */}
          <div className={styles.modalActions}>
            <button onClick={onClose} className={styles.btnCancelar}>Fechar</button>
          </div>

        </div>

        {/* Modal de Confirmação */}
        {modalConfirmacao && (
          <div className={styles.modalOverlay}>
            <div className={styles.modal}>
              <h3>Confirmação</h3>
              <p>
                Tem certeza que deseja adicionar a matéria
                {modalConfirmacao.acao === "ordem" ? ` à Ordem do Dia` : ` ao Expediente`} da Sessão Nº {sessaoAtual?.numero}?
              </p>
              <div className={styles.modalActions}>
                <button onClick={() => confirmarAcao()} className={styles.btnSim}>Sim</button>
                <button onClick={() => setModalConfirmacao(null)} className={styles.btnNao}>Não</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalPauta;
