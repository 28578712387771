import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaHome, FaUsers, FaChalkboardTeacher, FaCogs, FaSignOutAlt, FaArrowLeft, FaEye, FaFileAlt, FaEdit, FaVoteYea } from "react-icons/fa";
import styles from "./ComissaoDetalhes.module.css";
import ModalPauta from "../components/Modals/ModalPauta";
import ModalEditarPauta from "../components/Modals/ModalEditarPauta";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ComissaoDetalhes = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [comissao, setComissao] = useState(null);
  const [pautas, setPautas] = useState([]);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [modalPauta, setModalPauta] = useState(null);
  const [sessaoAtual, setSessaoAtual] = useState(null);
  const [modalEditarPauta, setModalEditarPauta] = useState(null);
  const [modalConfirmacao, setModalConfirmacao] = useState(null);
  
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

    useEffect(() => {
      const fetchDados = async () => {
        try {
          // Buscar pautas
          const responsePautas = await axios.get(`${process.env.REACT_APP_API}/pautas`);
          setPautas(responsePautas.data);
  
          // Buscar sessão atual
          const responseSessao = await axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`);
          setSessaoAtual(responseSessao.data);
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
        }
      };
  
      fetchDados();
    }, []);

  useEffect(() => {
    const fetchComissao = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/comissoes/${id}`);
        setComissao(response.data);
      } catch (error) {
        console.error("Erro ao buscar detalhes da comissão:", error);
      }
    };

    const fetchPautas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/pautas-comissoes`);
        const pautasRelacionadas = response.data.filter(
          (pautaComissao) => pautaComissao.comissaoId === parseInt(id)
        );

        const pautaIds = pautasRelacionadas.map((p) => p.pautaId);

        if (pautaIds.length > 0) {
          const pautasResponse = await axios.get(`${process.env.REACT_APP_API}/pautas`);
          const pautasFiltradas = pautasResponse.data.filter((pauta) =>
            pautaIds.includes(pauta.id)
          );
          setPautas(pautasFiltradas);
        } else {
          setPautas([]);
        }
      } catch (error) {
        console.error("Erro ao buscar pautas:", error);
      }
    };

    fetchComissao();
    fetchPautas();
  }, [id]);

  if (!comissao) return <p>Carregando...</p>;

  const toggleSubmenu = (menu) => {
    setActiveSubmenu(activeSubmenu === menu ? null : menu);
  };

  const abrirModalPauta = (pauta) => {
    setModalPauta(pauta);
  };

  const abrirModalEditarPauta = (pauta) => {
    setModalEditarPauta(pauta.id);
  };

  const fecharModal = () => {
    setModalPauta(null);
  };

  const pautasPorStatus = pautas.reduce((acc, pauta) => {
    if (!acc[pauta.status]) acc[pauta.status] = [];
    acc[pauta.status].push(pauta);
    return acc;
  }, {});
  
  const confirmarAcao = async () => {
    if (!modalConfirmacao) return;
    const { pautaId, tipo, acao } = modalConfirmacao;
    setModalConfirmacao(null);

    if (acao === "ordem") {
      await enviarParaOrdemConfirmado(pautaId);
    } else if (acao === "expediente") {
      await enviarParaExpedienteConfirmado(pautaId, tipo);
    }
  };

  const cancelarAcao = () => {
    setModalConfirmacao(null);
  };

  const enviarParaOrdem = async (pautaId) => {
    if (!sessaoAtual) {
      toast.error("Nenhuma sessão atual encontrada!");
      return;
    }
    setModalConfirmacao({ pautaId, acao: "ordem" });
  };

  const enviarParaOrdemConfirmado = async (pautaId) => {
    try {
      toast.loading("📨 Enviando matéria para Ordem do Dia...");
      await axios.post(`${process.env.REACT_APP_API}/pautas/${pautaId}/enviar-para-ordem/${sessaoAtual.id}`);
      toast.dismiss();
      toast.success("Matéria adicionada à Ordem do Dia!");
    } catch (error) {
      toast.dismiss();
      console.error("Erro ao enviar para Ordem do Dia:", error);
      toast.error("Erro ao enviar para Ordem do Dia!");
    }
  };

  const enviarParaExpediente = async (pautaId, tipo) => {
    if (!sessaoAtual) {
      toast.error("Nenhuma sessão atual encontrada!");
      return;
    }
    setModalConfirmacao({ pautaId, tipo, acao: "expediente" });
  };

  const enviarParaExpedienteConfirmado = async (pautaId, tipo) => {
    try {
      toast.loading("📨 Enviando matéria para Expediente...");
      await axios.post(`${process.env.REACT_APP_API}/pautas/${pautaId}/enviar-para-expediente/${sessaoAtual.id}`, { tipo });
      toast.dismiss();
      toast.success("Matéria adicionada ao Expediente!");
    } catch (error) {
      toast.dismiss();
      console.error("Erro ao enviar para Expediente:", error);
      toast.error("Erro ao enviar para Expediente!");
    }
  };

  const handleAnexarParecer = async (event, pautaId) => {
    const file = event.target.files[0];

    if (!file) return;

    const formData = new FormData();
    formData.append("parecer", file);

    try {
      toast.loading("📄 Anexando parecer...");
      await axios.post(`${process.env.REACT_APP_API}/pautas/${pautaId}/anexar-parecer`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.dismiss();
      toast.success("Parecer anexado com sucesso!");
      window.location.reload();
    } catch (error) {
      toast.dismiss();
      console.error("Erro ao anexar parecer:", error);
      toast.error("Erro ao anexar parecer. Tente novamente!");
    }
  };

  return (
    <div className={styles.painelContainer}>
      <>


      <div className={styles.content}>
        <header className={styles.dashboardHeader}>
        <h3>Detalhes da Comissão</h3>
          <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
        </header>

        <div className={styles.comissaoInfo}>
          <h2>{comissao.nome}</h2>
          <div className={styles.painelGrid}>
            <p><strong>Tipo:</strong> {comissao.tipo}</p>
            <p><strong>Presidente:</strong> {comissao.presidente || "Não definido"}</p>
            <p><strong>Vice-Presidentes:</strong> {comissao.vice_presidentes?.join(", ") || "Nenhum"}</p>
            <p><strong>Secretários:</strong> {comissao.secretarios?.join(", ") || "Nenhum"}</p>
            <p><strong>Relator:</strong> {comissao.relator || "Não definido"}</p>
          </div>
        </div>

        <nav className={styles.dockMenu}>
         <button className={styles.dockBtn} title="Voltar" onClick={() => navigate(-1)}>
           <FaArrowLeft />
         </button>
         <button className={styles.dockBtn} title="Dashboard" onClick={() => navigate("/dashboard")}>
           <FaHome />
         </button>
         <button className={styles.dockBtn} title="Controle da Sessão" onClick={() => navigate("/painel-controle")}>
           <FaChalkboardTeacher />
         </button>
         <button className={styles.dockBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
           <FaCogs />
         </button>
         <button className={styles.dockBtn} title="Logout" onClick={handleLogout}>
           <FaSignOutAlt />
         </button>
       </nav>
       
        <div className={styles.pautasContainer}>
          <h3>Matérias Vinculadas</h3>
          <div className={styles.section}>
            {pautas.length > 0 ? (
              Object.entries(pautasPorStatus || {}).map(([status, pautas]) => (
                <div key={status} className={styles.pautaGrupo}>
                  <h3>{status}</h3>
                  <table className={styles.pautaTabela}>
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Título</th>
            <th>Criação</th>
            <th>Status</th>
            <th>Parecer</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(pautas) && pautas.length > 0 ? (
            pautas.map((pauta) => (
              <tr key={pauta.id}>
                <td><FaFileAlt /> {pauta.tipo}</td>
                <td>{pauta.titulo}</td>
                <td>{pauta.criacao}</td>
                <td>{pauta.status}</td>
                <td>
                  <label className={styles.uploadButton}>
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      onChange={(e) => handleAnexarParecer(e, pauta.id)}
                      hidden
                    />
                    <FaFileAlt className={styles.icon} /> {pauta.parecer ? "Já Anexado" : "Clique para anexar"}
                  </label>
                </td>
                <td className={styles.acoes}>
                  <button onClick={() => enviarParaOrdem(pauta.id)} title="Enviar para Ordem do Dia">
                    <FaFileAlt />
                  </button>
                  <button onClick={() => enviarParaExpediente(pauta.id, "leitura_materia")} title="Enviar para Expediente">
                    <FaVoteYea />
                  </button>
                  <button onClick={() => abrirModalPauta(pauta)} title="Visualizar Pauta">
                    <FaEye />
                  </button>
                  <button onClick={() => abrirModalEditarPauta(pauta)} title="Editar Pauta">
                    <FaEdit />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">Nenhuma pauta encontrada.</td>
            </tr>
          )}
        </tbody>
      </table>
                </div>
              ))
            ) : (
              <p>Carregando pautas...</p>
            )}
          </div>
        </div>
      </div>

           {/* MODAL VER DETALHES */}
           {modalPauta && (
        <ModalPauta pautaId={modalPauta} onClose={fecharModal} />
      )}

      {/* MODAL EDITAR PAUTA */}
      {modalEditarPauta && (
        <ModalEditarPauta pautaId={modalEditarPauta} onClose={fecharModal} onSave={() => window.location.reload()} />
      )}
    
     {/* Modal de Confirmação */}
     {modalConfirmacao && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <h3>Confirmação</h3>
            <p>
              Tem certeza que deseja adicionar a matéria
              {modalConfirmacao.acao === "ordem" ? ` à Ordem do Dia` : ` ao Expediente`} da Sessão Nº {sessaoAtual?.numero}?
            </p>
            <div className={styles.modalButtons}>
              <button onClick={confirmarAcao} className={styles.btnConfirm}>Sim</button>
              <button onClick={cancelarAcao} className={styles.btnCancel}>Não</button>
            </div>
          </div>
        </div>
      )}
</>
      <ToastContainer />
    </div>
  );
  
};

export default ComissaoDetalhes;
