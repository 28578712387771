import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  FaHome, FaCalendarPlus, FaFileAlt, FaUsers, FaVoteYea, FaArrowLeft, FaCogs, FaSignOutAlt, FaPlusCircle, FaGavel, FaTimes, FaClipboardList,
  FaChalkboardTeacher,
} from "react-icons/fa";
import styles from "./Config.module.css";
import ModalNovaProposicao from "../components/Modals/ModalNovaProposicao";
import ModalConfiguracaoSessao from "../components/Modals/ModalConfiguracaoSessao";
import ModalCriarUsuario from "../components/Modals/ModalCriacaoUsuario";
import ModalCriarComissao from "../components/Modals/ModalCriarComissao";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalCriarAta from "../components/Modals/ModalCriarAta";
import ModalVotacao from "../components/Modals/ModalVotacao";
import { RiGovernmentLine } from "react-icons/ri";
import { MdGroups } from "react-icons/md";

const Config = () => {
  const navigate = useNavigate();
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [modalProposicaoOpen, setModalProposicaoOpen] = useState(false);
  const [modalSessaoOpen, setModalSessaoOpen] = useState(false);
  const [modalUsuarioOpen, setModalUsuarioOpen] = useState(false);
  const [modalComissaoOpen, setModalComissaoOpen] = useState(false);
  const { id } = useParams();
  const [sessaoAtual, setSessaoAtual] = useState(null);
  const [modalAtaOpen, setModalAtaOpen] = useState(false);
  const [mostrarModalVotacao, setMostrarModalVotacao] = useState(false);
  const existeSessaoAtiva = sessaoAtual?.status === "aberta" || sessaoAtual?.status === "agendada";
  
  const fetchDados = async () => {  // Agora está no escopo global do componente
    try {
      const responseSessao = await axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`);
      setSessaoAtual(responseSessao.data);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };
  
  useEffect(() => {
    fetchDados();
  }, []);  

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const usuarioId = localStorage.getItem("usuarioId"); // Pegando o ID do usuário logado

  const handleSalvarProposicao = async (dados) => {
    if (!dados || !dados.titulo) {
      console.error("Erro: Dados inválidos recebidos:", dados);
      alert("Erro: Dados inválidos! Verifique os campos.");
      return;
    }
  
    if (!usuarioId) {
      alert("Erro: Usuário não autenticado!");
      return;
    }
  
    const formDataToSend = new FormData();
    formDataToSend.append("usuarioId", usuarioId);
    formDataToSend.append("titulo", dados.titulo);
    formDataToSend.append("numero", dados.numero);
    formDataToSend.append("tipo", dados.tipo);
    formDataToSend.append("comissaoId", Array.isArray(dados.comissaoId) ? dados.comissaoId.join(", ") : dados.comissaoId || "");
    formDataToSend.append("minuta", dados.minuta || "");
    formDataToSend.append("texto_completo", dados.texto_completo || "");
    formDataToSend.append("autores", Array.isArray(dados.autores) ? dados.autores.join(", ") : dados.autores || "");
  
    if (dados.anexo) {
      formDataToSend.append("anexo", dados.anexo);
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/pautas`, {
        method: "POST",
        body: formDataToSend,
      });
  
      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Erro no backend:", errorResponse);
        throw new Error(errorResponse.message || "Erro ao salvar proposição!");
      }
  
      toast.success("Proposição salva com sucesso!");
      setModalProposicaoOpen(false);
    } catch (error) {
      console.error("Erro ao salvar:", error);
      toast.error("Erro ao salvar a proposição!");
    }
  };  
  
  return (
      <>
            <div className={styles.content}>

      {/* 🔹 Conteúdo principal */}

        <header className={styles.dashboardHeader}>
        <h3>Painel Administrativo</h3>
          <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
        </header>


     {/* 🔹 Menu inferior fixo estilo Dock */}
    
  <nav className={styles.dockMenu}>
    <button className={styles.dockBtn} title="Voltar" onClick={() => navigate(-1)}>
      <FaArrowLeft />
    </button>
    <button className={styles.dockBtn} title="Dashboard" onClick={() => navigate("/dashboard")}>
      <FaHome />
    </button>
    <button className={styles.dockBtn} title="Controle da Sessão" onClick={() => navigate("/painel-controle")}>
      <FaChalkboardTeacher />
    </button>
    <button className={styles.dockBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
      <FaCogs />
    </button>
    <button className={styles.dockBtn} title="Logout" onClick={handleLogout}>
      <FaSignOutAlt />
    </button>
  </nav>
  
        <div className={styles.gridContainer}>
          {/* 🔹 Sessões */}
          <div className={styles.card}>
            <FaCalendarPlus className={styles.icon} />
            <h2>Sessões</h2>
            <button className={styles.btnAction} onClick={() => setModalSessaoOpen(true)}>
  {existeSessaoAtiva ? (
    <>
      <FaCogs /> Editar Sessão
    </>
  ) : (
    <>
      <FaPlusCircle /> Nova Sessão
    </>
  )}
</button>
            <button className={styles.btnAction} onClick={() => navigate("/sessoes-anteriores")}>
              <FaCogs /> Gerenciar
            </button>
          </div>

        {/* 🔹 Matérias (proposições: projetos de lei, requerimentos, etc.) */}
<div className={styles.card}>
  <FaFileAlt className={styles.icon} />
  <h2>Matérias</h2>
  <button className={styles.btnAction} onClick={() => setModalProposicaoOpen(true)}>
    <FaPlusCircle /> Nova Matéria
  </button>
  <button className={styles.btnAction} onClick={() => navigate("/pautas-gerenciar")}>
    <FaCogs /> Gerenciar
  </button>
</div>

{/* 🔹 Atas */}
<div className={styles.card}>
  <FaFileAlt className={styles.icon} />
  <h2>Atas</h2>
  <button className={styles.btnAction} onClick={() => setModalAtaOpen(true)}>
    <FaPlusCircle /> Nova Ata
  </button>
  <button className={styles.btnAction} onClick={() => navigate("/atas-gerenciar")}>
    <FaCogs /> Gerenciar
  </button>
</div>

{/* 🔹 Sessões Legislativas */}
<div className={styles.card}>
  <RiGovernmentLine className={styles.icon} />
  <h2>Sessões</h2>
  <button className={styles.btnAction} onClick={() => navigate("/sessao/criar")}>
    <FaPlusCircle /> Nova Sessão
  </button>
  <button className={styles.btnAction} onClick={() => navigate("/sessoes")}>
    <FaCogs /> Gerenciar
  </button>
</div>

{/* 🔹 Votações */}
<div className={styles.card}>
  <FaVoteYea className={styles.icon} />
  <h2>Votações</h2>
  <button 
    className={styles.btnAction} 
    onClick={() => setMostrarModalVotacao(true)}
  >
    <FaPlusCircle /> Nova Votação
  </button>
  <button className={styles.btnAction} onClick={() => navigate("/configuracao")}>
    <FaCogs /> Gerenciar
  </button>
</div>

{/* 🔹 Comissões */}
<div className={styles.card}>
  <MdGroups className={styles.icon} />
  <h2>Comissões</h2>
  <button 
    className={styles.btnAction} 
    onClick={() => modalComissaoOpen(true)}
  >
    <FaPlusCircle /> Nova Comissão
  </button>
  <button className={styles.btnAction} onClick={() => navigate("/comissoes")}>
    <FaCogs /> Gerenciar
  </button>
</div>

        </div>
      </div>

      {/* Modais */}
      {modalProposicaoOpen && (
        <ModalNovaProposicao 
          closeModal={() => setModalProposicaoOpen(false)}
          onSave={handleSalvarProposicao}  // Agora ele recebe os dados corretamente!
        />
      )}

      {modalSessaoOpen && (
        <ModalConfiguracaoSessao 
        isOpen={modalSessaoOpen} 
        closeModal={() => setModalSessaoOpen(false)} 
      />
      )}
      {modalUsuarioOpen && (
        <ModalCriarUsuario 
          closeModal={() => setModalUsuarioOpen(false)} // Fechar modal corretamente
        />
      )}
      {modalComissaoOpen && <ModalCriarComissao closeModal={() => setModalComissaoOpen(false)} />}

      {modalAtaOpen && <ModalCriarAta show={modalAtaOpen} handleClose={() => setModalAtaOpen(false)} />}

{mostrarModalVotacao && (
  <ModalVotacao 
    sessaoId={sessaoAtual?.id}  // Corrigido
    fecharModal={() => setMostrarModalVotacao(false)} 
    atualizarDados={fetchDados}  // Corrigido
  />
)}

    </>
  );
};

export default Config;
