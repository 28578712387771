import { Navigate, Outlet } from "react-router-dom";
import { isTokenValid, logout } from "../utils/auth";

export default function PrivateRoute({ rolesAllowed }) {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role") || "vereador";
  const userTipo = localStorage.getItem("tipo") || "vereador";

  if (!token || !isTokenValid()) {
    console.warn("🔒 Sessão inválida ou expirada. Redirecionando para login...");
    logout();
    return <Navigate to="/login" />;
  }

  // 🔐 Acesso total para quem é admin, presidente ou colaborador
  const FULL_ACCESS_ROLES = ["admin", "presidente", "colaborador"];
  const FULL_ACCESS_TYPES = ["admin"];

  if (
    FULL_ACCESS_TYPES.includes(userTipo) ||
    FULL_ACCESS_ROLES.includes(userRole) ||
    rolesAllowed.includes(userRole)
  ) {
    return <Outlet />;
  }

  console.warn(`🚫 Acesso negado: Cargo "${userRole}" (${userTipo}) não tem permissão para acessar.`);
  return <Navigate to="/dashboard" />;
}
