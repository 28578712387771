import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaFileAlt, FaHome, FaCogs, FaSignOutAlt, FaArrowLeft,
FaEye, FaEdit, FaVoteYea, FaCheck, FaCalendarAlt, FaTv, FaChalkboardTeacher, FaSort, FaTimes, FaBars, FaSearch, FaFileDownload } from "react-icons/fa";
import styles from "./Styles.module.css";
import ModalPauta from "../components/Modals/ModalPauta";
import ModalEditarPauta from "../components/Modals/ModalEditarPauta";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalNovaProposicao from "../components/Modals/ModalNovaProposicao";

const PautasGerenciar = () => {
  const navigate = useNavigate();
  const [pautas, setPautas] = useState([]);
  const [sessaoAtual, setSessaoAtual] = useState(null);
  const [modalPauta, setModalPauta] = useState(null);
  const [modalEditarPauta, setModalEditarPauta] = useState(null);
  const [modalConfirmacao, setModalConfirmacao] = useState(null);
  const [filtroStatus, setFiltroStatus] = useState("");
  const [filtroTipo, setFiltroTipo] = useState("");
  const [termoBusca, setTermoBusca] = useState("");
  const [ordenacao, setOrdenacao] = useState({ coluna: "criacao", asc: true });
  const [modalProposicaoOpen, setModalProposicaoOpen] = useState(false);

  useEffect(() => {
    const fetchDados = async () => {
      try {
        // Buscar pautas
        const responsePautas = await axios.get(`${process.env.REACT_APP_API}/pautas`);
        setPautas(responsePautas.data);

        // Buscar sessão atual
        const responseSessao = await axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`);
        setSessaoAtual(responseSessao.data);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchDados();
  }, []);

  
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const usuarioId = localStorage.getItem("usuarioId"); // Pegando o ID do usuário logado

  // Filtrando os dados
  const pautasFiltradas = pautas
    .filter((pauta) => (filtroStatus ? pauta.status === filtroStatus : true))
    .filter((pauta) => (filtroTipo ? pauta.tipo === filtroTipo : true))
    .filter((pauta) =>
      termoBusca
        ? Object.values(pauta).some(
            (valor) =>
              typeof valor === "string" &&
              valor.toLowerCase().includes(termoBusca.toLowerCase())
          )
        : true
    )
    .sort((a, b) => {
      if (a[ordenacao.coluna] < b[ordenacao.coluna]) return ordenacao.asc ? -1 : 1;
      if (a[ordenacao.coluna] > b[ordenacao.coluna]) return ordenacao.asc ? 1 : -1;
      return 0;
    });

    const handleSalvarProposicao = async (dados) => {
      if (!dados || !dados.titulo) {
        console.error("Erro: Dados inválidos recebidos:", dados);
        alert("Erro: Dados inválidos! Verifique os campos.");
        return;
      }
    
      if (!usuarioId) {
        alert("Erro: Usuário não autenticado!");
        return;
      }
    
      const formDataToSend = new FormData();
      formDataToSend.append("usuarioId", usuarioId);
      formDataToSend.append("titulo", dados.titulo);
      formDataToSend.append("numero", dados.numero);
      formDataToSend.append("tipo", dados.tipo);
      formDataToSend.append("comissaoId", Array.isArray(dados.comissaoId) ? dados.comissaoId.join(", ") : dados.comissaoId || "");
      formDataToSend.append("minuta", dados.minuta || "");
      formDataToSend.append("texto_completo", dados.texto_completo || "");
      formDataToSend.append("autor", Array.isArray(dados.autores) ? dados.autores.join(", ") : dados.autores || "");
    
      if (dados.anexo) {
        formDataToSend.append("anexo", dados.anexo);
      }
    
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/pautas`, {
          method: "POST",
          body: formDataToSend,
        });
    
        if (!response.ok) {
          const errorResponse = await response.json();
          console.error("Erro no backend:", errorResponse);
          throw new Error(errorResponse.message || "Erro ao salvar proposição!");
        }
    
        toast.success("Proposição salva com sucesso!");
        setModalProposicaoOpen(false);
      } catch (error) {
        console.error("Erro ao salvar:", error);
        toast.error("Erro ao salvar a proposição!");
      }
    };  

  const confirmarAcao = async () => {
    if (!modalConfirmacao) return;
    const { pautaId, tipo, acao } = modalConfirmacao;
    setModalConfirmacao(null);

    if (acao === "ordem") {
      await enviarParaOrdemConfirmado(pautaId);
    } else if (acao === "expediente") {
      await enviarParaExpedienteConfirmado(pautaId, tipo);
    }
  };

  const cancelarAcao = () => {
    setModalConfirmacao(null);
  };

  const enviarParaOrdemConfirmado = async (pautaId) => {
    try {
      toast.loading("📨 Enviando matéria para Ordem do Dia...");
      await axios.post(`${process.env.REACT_APP_API}/pautas/${pautaId}/enviar-para-ordem/${sessaoAtual.id}`);
      toast.dismiss();
      toast.success("Matéria adicionada à Ordem do Dia!");
    } catch (error) {
      toast.dismiss();
      console.error("Erro ao enviar para Ordem do Dia:", error);
      toast.error("Erro ao enviar para Ordem do Dia!");
    }
  };

  const enviarParaExpedienteConfirmado = async (pautaId, tipo) => {
    try {
      toast.loading("📨 Enviando matéria para Expediente...");
      await axios.post(`${process.env.REACT_APP_API}/pautas/${pautaId}/enviar-para-expediente/${sessaoAtual.id}`, { tipo });
      toast.dismiss();
      toast.success("Matéria adicionada ao Expediente!");
    } catch (error) {
      toast.dismiss();
      console.error("Erro ao enviar para Expediente:", error);
      toast.error("Erro ao enviar para Expediente!");
    }
  };

  const abrirModalPauta = (pauta) => {
    setModalPauta(pauta.id);
  };

  const abrirModalEditarPauta = (pauta) => {
    setModalEditarPauta(pauta.id);
  };

  const fecharModal = () => {
    setModalPauta(null);
    setModalEditarPauta(null);
  };

  return (
    <div className={styles.content}>
    <>

      {/* CABEÇALHO */}
        <header className={styles.dashboardHeader}>
        <h3>Gerenciamento de Matérias</h3>
          <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
        </header>

  
                   {/* 🔹 Menu inferior fixo estilo Dock */}
    
                <nav className={styles.dockMenu}>
                  <button className={styles.dockBtn} title="Voltar" onClick={() => navigate(-1)}>
                    <FaArrowLeft />
                  </button>
                  <button className={styles.dockBtn} title="Dashboard" onClick={() => navigate("/dashboard")}>
                    <FaHome />
                  </button>
                  <button className={styles.dockBtn} title="Controle da Sessão" onClick={() => navigate("/painel-controle")}>
                    <FaChalkboardTeacher />
                  </button>
                  <button className={styles.dockBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
                    <FaCogs />
                  </button>
                  <button className={styles.dockBtn} title="Logout" onClick={handleLogout}>
                    <FaSignOutAlt />
                  </button>
                </nav>  

        <div className={styles.contentGerenciar}>
          <div className={styles.section}>
            {/* Filtros Estilizados */}
            <div className={styles.filtrosContainer}>
               <div  className={styles.filtroCard}>
                <FaSearch className={styles.filtroIcon} />
                <input
                  type="text"
                  placeholder="Buscar por título, número ou autor..."
                  value={termoBusca}
                  onChange={(e) => setTermoBusca(e.target.value)}
                />
              </div>
            </div>

<div className={styles.pautasGrid}>
  {pautasFiltradas.length > 0 ? (
    pautasFiltradas.map(pauta => (
      <div
        key={pauta.id}
        className={styles.pautaCard}
        data-status={pauta.status.toLowerCase()}
        onClick={() => abrirModalPauta(pauta)}
      >
        <div className={styles.pautaInfo}>
          <h3>{pauta.tipo}</h3>
        </div>

        <span className={styles.pautaStatus}>{pauta.status}</span>

        <div className={styles.pautaDetalhe}>
          <p>Título:</p><strong>{pauta.titulo}</strong>
        </div>

        <div className={styles.pautaDetalhe}>
          <FaCalendarAlt className={styles.icon} />
          <p><strong>Data:</strong> {pauta.criacao}</p>
        </div>
        <div className={styles.acoesDetalhes}>

          {pauta.anexo && (
            <a
              href={`${process.env.REACT_APP_API}/uploads/${pauta.anexo}`}
              target="_blank"
              rel="noopener noreferrer"
              title="Baixar anexos"
              onClick={(e) => e.stopPropagation()}
              className={`${styles.btnDetalhes}`}
            >
              <FaFileDownload /> Download
            </a>
          )}
          <button
            onClick={(e) => { e.stopPropagation(); abrirModalPauta(pauta); }}
            className={`${styles.btnDetalhes}`}
          >
            <FaEye /> Detalhes
          </button>
          <button
            onClick={(e) => { e.stopPropagation(); abrirModalEditarPauta(pauta); }}
            className={`${styles.btnDetalhes}`}
          >
            <FaEdit /> Administrar
          </button>
          </div>
      </div>
    ))
  ) : (
    <p>Nenhuma pauta encontrada.</p>
  )}
</div>

           
          </div>

           {/* MODAL VER DETALHES */}
      {modalPauta && (
        <ModalPauta pautaId={modalPauta} onClose={fecharModal} />
      )}

      {/* MODAL EDITAR PAUTA */}
      {modalEditarPauta && (
        <ModalEditarPauta pautaId={modalEditarPauta} onClose={fecharModal} onSave={() => window.location.reload()} />
      )}
         {/* Modal de Confirmação */}
         {modalConfirmacao && (
            <div className={styles.modalOverlay}>
              <div className={styles.modal}>
                <h3>Confirmação</h3>
                <p>
                  Tem certeza que deseja incluir a matéria
                  {modalConfirmacao.acao === "ordem" ? ` à Ordem do Dia` : ` ao Expediente`} da Sessão Nº <strong>{sessaoAtual?.numero}</strong>?
                </p>
                <div className={styles.modalButtons}>
                  <button onClick={confirmarAcao} className={styles.btnConfirm}>Sim</button>
                  <button onClick={cancelarAcao} className={styles.btnCancel}>Não</button>
                </div>
              </div>
            </div>
          )}

           {/* Modais */}
      {modalProposicaoOpen && (
        <ModalNovaProposicao 
          closeModal={() => setModalProposicaoOpen(false)}
          onSave={handleSalvarProposicao}  // Agora ele recebe os dados corretamente!
        />
      )}
        </div>
        <ToastContainer />
        </>
    </div>
  );
};

export default PautasGerenciar;