import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Styles.module.css";
import { FaTv, FaScroll, FaAlignLeft, FaEye, FaUsers, FaCogs, FaChalkboardTeacher, FaGavel, FaArrowLeft, FaPlusCircle, FaFileAlt, FaVoteYea, FaHome, FaUser, FaSignOutAlt, FaCheckCircle, FaTimesCircle, FaBalanceScale, FaTimes } from "react-icons/fa";
import Modal from "react-modal";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ModalNovaProposicao from "../components/Modals/ModalNovaProposicao";
import ModalPauta from "../components/Modals/ModalPauta";
import socket from "./socket";

Modal.setAppElement("#root");

const Dashboard = () => {
  const navigate = useNavigate();
  const [ordemDoDia, setOrdemDoDia] = useState([]);
  const [sessaoAtual, setSessaoAtual] = useState(null);
  const [sessao, setSessao] = useState(null);
  const [ritoSessao, setRitoSessao] = useState([]); // Define corretamente
  const [vereadoresPresentes, setVereadoresPresentes] = useState([]);
  const [pautaSelecionada, setPautaSelecionada] = useState(null);
  const [painelItens, setPainelItens] = useState([]);
  const [urnaAberta, setUrnaAberta] = useState(false);
  const [modalAberto, setModalAberto] = useState(false);
  const [votacoesAndamento, setVotacoesAndamento] = useState(false);
  const [modalProposicaoOpen, setModalProposicaoOpen] = useState(false);
  const usuarioId = localStorage.getItem("usuarioId");
  const [menuAberto, setMenuAberto] = useState(false);
  const [itensPainel, setItensPainel] = useState([]);
  const [votosUsuario, setVotosUsuario] = useState([]);
  const [sessaoId, setSessaoId] = useState(null);
  const [exibirExpediente, setExibirExpediente] = useState(true);
  const [sessaoOrdem, setSessaoOrdem] = useState([]);
  const [sessaoExpediente, setSessaoExpediente] = useState([]);
  const [sessaoTribuna, setSessaoTribuna] = useState([]);
  const [exibirOrdem, setExibirOrdem] = useState(true);
  const [exibirTribuna, setExibirTribuna] = useState(true);
  const [modalPauta, setModalPauta] = useState(null);
  const [expediente, setExpediente] = useState([]);
  const [ordemDia, setOrdemDia] = useState([]);
  const [tribuna, setTribuna] = useState([]);
  const [itensExibidos, setItensExibidos] = useState([]);
  const [pautasDisponiveis, setPautasDisponiveis] = useState([]);
  const cargoUsuario = localStorage.getItem("role") || "vereador";

  useEffect(() => {
    socket.on("connect", () => {
      console.log("🟢 Socket conectado:", socket.id);
    });
  
    return () => {
      socket.disconnect();
    };
  }, []);
  
  useEffect(() => {
    buscarDados();
    const interval = setInterval(buscarDados, 3000);
    return () => clearInterval(interval);
  }, []);  

    const fetchDados = async () => {  // Agora está no escopo global do componente
      try {
        const responseSessao = await axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`);
        setSessaoAtual(responseSessao.data);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    
    const buscarDados = async () => {
      try {
        const sessaoRes = await axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`);
    
        if (!sessaoRes.data || sessaoRes.data.status === "encerrada") {
          setSessao(null);
          setItensExibidos([]);
          setSessaoOrdem([]);
          setSessaoExpediente([]);
          setSessaoTribuna([]);
          return;
        }
    
        setSessao(sessaoRes.data);
    
        const [painelRes, ordemRes, expedienteRes, tribunaRes, pautasRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API}/painel-sessao`),
          axios.get(`${process.env.REACT_APP_API}/sessao-ordem/${sessaoRes.data.id}`),
          axios.get(`${process.env.REACT_APP_API}/sessao-expediente/${sessaoRes.data.id}`),
          axios.get(`${process.env.REACT_APP_API}/sessao-tribuna/${sessaoRes.data.id}`),
          axios.get(`${process.env.REACT_APP_API}/pautas`),
        ]);
    
        setItensExibidos(painelRes.data?.filter((item) => item.status !== "concluido") || []);
        setSessaoOrdem(ordemRes.data || []);
        setSessaoExpediente(expedienteRes.data || []);
        setSessaoTribuna(tribunaRes.data || []);
    
        const pautasNaoEnviadas = pautasRes.data.filter(pauta =>
          !ordemRes.data.some(ordem => ordem.pauta_id === pauta.id)
        );
        setPautasDisponiveis(pautasNaoEnviadas);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    
  const abrirModalPauta = (item) => {
    const pautaId =
      item.pauta_id ||
      item.pauta?.id || // acessa o ID real se tiver `pauta` como objeto
      item.id;
  
    if (!pautaId || isNaN(Number(pautaId))) {
      toast.error("ID da pauta inválido.");
      return;
    }
  
    setModalPauta(pautaId);
  };  
  
  const fecharModal = () => {
    setModalPauta(null);
  };
  

useEffect(() => {
  // 🔍 Buscar a sessão atual
  const fetchSessaoAtual = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`);

      if (res.data) {
        setSessaoAtual(res.data);
        setSessaoId(res.data.id); // 👈 Define o sessaoId necessário nos outros efeitos

        // Processa o rito da sessão
        if (Array.isArray(res.data.rito)) {
          setRitoSessao(res.data.rito);
        } else if (typeof res.data.rito === "string") {
          try {
            const parsed = JSON.parse(res.data.rito);
            setRitoSessao(Array.isArray(parsed) ? parsed : []);
          } catch (err) {
            console.error("Erro ao converter rito:", err);
            setRitoSessao([]);
          }
        } else {
          setRitoSessao([]);
        }
      } else {
        setSessaoAtual(null);
        setSessaoId(null);
        setRitoSessao([]);
      }
    } catch (err) {
      console.error("Erro ao buscar sessão atual:", err);
    }
  };

  fetchSessaoAtual();
}, []);

useEffect(() => {
  if (!sessaoId) return;

  const fetchDadosSessao = async () => {
    try {
      const [expedienteRes, ordemRes, tribunaRes] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API}/sessao-expediente/${sessaoId}`),
        axios.get(`${process.env.REACT_APP_API}/sessao-ordem/${sessaoId}`),
        axios.get(`${process.env.REACT_APP_API}/sessao-tribuna/${sessaoId}`)
      ]);
      setExpediente(expedienteRes.data || []);
      setOrdemDia(ordemRes.data || []);
      setTribuna(tribunaRes.data || []);
    } catch (error) {
      console.error("Erro ao carregar dados da sessão:", error);
    }
  };

  fetchDadosSessao();
}, [sessaoId]);

const formatarTempo = (segundos) => {
  const horas = String(Math.floor(segundos / 3600)).padStart(2, "0");
  const minutos = String(Math.floor((segundos % 3600) / 60)).padStart(2, "0");
  const seg = String(segundos % 60).padStart(2, "0");
  return `${horas}:${minutos}:${seg}`;
};


// 🔄 Carrega painel da sessão
useEffect(() => {
  if (!sessaoId) return;

  axios
    .get(`${process.env.REACT_APP_API}/painel-sessao/${sessaoId}`)
    .then((response) => setItensPainel(response.data))
    .catch((error) => console.error("Erro ao carregar painel da sessão:", error));
}, [sessaoId]);

// 🔐 Logout
const handleLogout = () => {
  localStorage.removeItem("token");
  navigate("/login");
};

    const handleSalvarProposicao = async (dados) => {
      if (!dados || !dados.titulo) {
        console.error("Erro: Dados inválidos recebidos:", dados);
        alert("Erro: Dados inválidos! Verifique os campos.");
        return;
      }
    
      if (!usuarioId) {
        alert("Erro: Usuário não autenticado!");
        return;
      }
    
      const formDataToSend = new FormData();
      formDataToSend.append("usuarioId", usuarioId);
      formDataToSend.append("titulo", dados.titulo);
      formDataToSend.append("numero", dados.numero);
      formDataToSend.append("tipo", dados.tipo);
      formDataToSend.append("comissaoId", Array.isArray(dados.comissaoId) ? dados.comissaoId.join(", ") : dados.comissaoId || "");
      formDataToSend.append("minuta", dados.minuta || "");
      formDataToSend.append("texto_completo", dados.texto_completo || "");
      formDataToSend.append("autor", Array.isArray(dados.autores) ? dados.autores.join(", ") : dados.autores || "");
    
      if (dados.anexo) {
        formDataToSend.append("anexo", dados.anexo);
      }
    
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/pautas`, {
          method: "POST",
          body: formDataToSend,
        });
    
        if (!response.ok) {
          const errorResponse = await response.json();
          console.error("Erro no backend:", errorResponse);
          throw new Error(errorResponse.message || "Erro ao salvar proposição!");
        }
    
        toast.success("Proposição salva com sucesso!");
        setModalProposicaoOpen(false);
      } catch (error) {
        console.error("Erro ao salvar:", error);
        toast.error("Erro ao salvar a proposição!");
      }
    };  

    const abrirModalVotacao = async () => {
      try {
        const usuarioId = localStorage.getItem("usuarioId");
        if (!usuarioId || !sessaoAtual?.id) return;
    
        const votacoesRes = await axios.get(
          `${process.env.REACT_APP_API}/votacoes?sessaoId=${sessaoAtual.id}&status=andamento`
        );
        const votacoes = votacoesRes.data;
    
        const pautas = [];
    
        for (const votacao of votacoes) {
          const votoRes = await axios.get(
            `${process.env.REACT_APP_API}/voto/verificar/${usuarioId}/${votacao.id}`
          );
    
          if (!votoRes.data.jaVotou) {
            const pautaRes = await axios.get(`${process.env.REACT_APP_API}/pautas/${votacao.pautaId}`);
            pautas.push(pautaRes.data);
          }
        }
    
        if (pautas.length === 0) {
          setModalAberto(false);
          return;
        }
    
        // Agora usamos uma lista de pautas
        setPautaSelecionada(pautas);
        setModalAberto(true);
      } catch (error) {
        console.error("Erro ao buscar pautas para votação:", error);
        toast.error("Erro ao abrir votação.");
      }
    };    

  // Formatar data por extenso
  const formatarData = (dataISO) => {
    const data = new Date(dataISO);
    return data.toLocaleDateString("pt-BR", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  const materiasExibidas = itensPainel.filter(
    item => ["exibindo", "votacao"].includes(item.status?.toLowerCase())
  );  

  const registrarVoto = async (voto) => {
    try {
      const usuarioId = localStorage.getItem("usuarioId");
      const usuarioNome = localStorage.getItem("usuarioNome") || "Usuário Desconhecido";
  
      if (!usuarioId || !sessaoAtual?.id) {
        toast.error("Erro: Sessão ou usuário inválido.");
        return;
      }
  
      // 🔍 Buscar todas as votações em andamento da sessão atual
      const votacoesRes = await axios.get(`${process.env.REACT_APP_API}/votacoes?sessaoId=${sessaoAtual.id}&status=andamento`);
      const votacoes = votacoesRes.data;
  
      if (!votacoes.length) {
        toast.error("Nenhuma votação em andamento encontrada.");
        return;
      }
  
      // Contador para votos registrados
      let votosRegistrados = 0;
  
      for (const votacao of votacoes) {
        const votacaoId = votacao.id;
  
        // Verifica se já votou nessa votação
        const votoRes = await axios.get(
          `${process.env.REACT_APP_API}/voto/verificar/${usuarioId}/${votacaoId}`
        );
  
        if (votoRes.data?.jaVotou) {
          console.log(`⚠️ Já votou na pauta ${votacao.pautaId}`);
          continue;
        }
  
        // Registra o voto
        await axios.post(`${process.env.REACT_APP_API}/voto/registrar`, {
          pautaId: votacao.pautaId,
          escolha: voto,
          usuarioId: Number(usuarioId),
          usuarioNome,
          sessaoId: sessaoAtual.id,
          votacaoId,
        });
  
        console.log(`Voto registrado para pauta ${votacao.pautaId}`);
        votosRegistrados++;
      }
  
      if (votosRegistrados > 0) {
        toast.success(`Voto registrado em ${votosRegistrados} pauta(s)!`);
      } else {
        toast.info("⚠️ Você já votou em todas as pautas em andamento.");
      }
  
      setModalAberto(false);
    } catch (error) {
      console.error("Erro ao registrar votos:", error.response ? error.response.data : error);
      toast.error("Erro ao registrar votos.");
    }
  };  
  
  useEffect(() => {
    const fetchVotacoes = async () => {
      try {
        if (!sessaoAtual?.id) return;
  
        const response = await axios.get(`${process.env.REACT_APP_API}/votacoes?sessaoId=${sessaoAtual.id}&status=andamento`);
        const votacoes = response.data;
  
        const usuarioId = localStorage.getItem("usuarioId");
  
        for (const votacao of votacoes) {
          const votoRes = await axios.get(
            `${process.env.REACT_APP_API}/voto/verificar/${usuarioId}/${votacao.id}`
          );
  
          if (!votoRes.data.jaVotou) {
            abrirModalVotacao(); // ❗ Sem parâmetro → modal mais genérico
            return;
          }
        }
  
        setModalAberto(false);
      } catch (error) {
        console.error("Erro ao buscar votações em andamento:", error);
      }
    };
  
    if (sessaoAtual?.id) {
      const interval = setInterval(fetchVotacoes, 3000);
      return () => clearInterval(interval);
    }
  }, [sessaoAtual]);
  
  return (
    <div className={styles.content}>
      {/* MENU LATERAL */}
      <>
  <nav className={styles.dockMenu}>
    <button className={styles.dockBtn} title="Voltar" onClick={() => navigate(-1)}>
      <FaArrowLeft />
    </button>

    <button className={styles.dockBtn} title="Dashboard" onClick={() => navigate("/dashboard")}>
      <FaHome />
    </button>

    {/* 🔹 "Nova Proposição" - Só para vereador */}
    {cargoUsuario === "vereador" && (
      <button className={styles.dockBtn} title="Adicionar proposição" onClick={() => setModalProposicaoOpen(true)}>
        <FaPlusCircle />
      </button>
    )}

    {/* 🔹 Botões extras - apenas para quem NÃO for vereador */}
    {cargoUsuario !== "vereador" && (
      <>
        <button className={styles.dockBtn} title="Controle da Sessão" onClick={() => navigate("/painel-controle")}>
          <FaChalkboardTeacher />
        </button>

        <button className={styles.dockBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
          <FaCogs />
        </button>
      </>
    )}

    <button className={styles.dockBtn} title="Logout" onClick={handleLogout}>
      <FaSignOutAlt />
    </button>
  </nav>

      {/* CONTEÚDO PRINCIPAL */}
        {/* CABEÇALHO */}
        <header className={styles.dashboardHeader}>
        <h3>Painel Legislativo</h3>
          <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
        </header>

        <div className={styles.dashboardContainer}>

        <section className={styles.section}>
  <h2><FaTv/> {sessaoAtual ? `Sessão ${sessaoAtual.tipo} nº ${sessaoAtual.numero} - ${formatarData(sessaoAtual.data)}` : "Nenhuma Sessão disponível."}</h2>

                     {/* Filtros Estilizados */}
      <div className={styles.filtrosContainer}>
        <div className={`${styles.filtroCard} ${exibirExpediente ? styles.ativo : ""}`} onClick={() => setExibirExpediente(!exibirExpediente)}>
          <FaAlignLeft className={styles.filtroIcon} />
          <div>
            <div>Expediente</div>
          </div>
        </div>

        <div className={`${styles.filtroCard} ${exibirOrdem ? styles.ativo : ""}`} onClick={() => setExibirOrdem(!exibirOrdem)}>
          <FaFileAlt className={styles.filtroIcon} />
          <div>
            <div>Ordem do Dia</div>
          </div>
        </div>

        <div className={`${styles.filtroCard} ${exibirTribuna ? styles.ativo : ""}`} onClick={() => setExibirTribuna(!exibirTribuna)}>
          <FaUsers className={styles.filtroIcon} />
          <div>
            <div>Tribuna</div>
          </div>
        </div>
      </div>

      {/* Seção: Expediente da Sessão */}
      {exibirExpediente && (
        <div className={styles.pautaGrupo}>
          <h3>Matérias do Expediente da Sessão</h3>
          <ul>
            {sessaoExpediente.length > 0 ? (
              sessaoExpediente.map((item) => (
                <li key={item.id}>
                  <strong><FaFileAlt /> {item.titulo || item.pauta?.titulo || "Sem título"}</strong>
                  <div className={styles.dashboardButton}>
                  <button onClick={() => abrirModalPauta(item)} title="Detalhes da Matérias">
                    <FaEye /> Detalhes
                  </button>
                  </div>
                </li>
              ))
            ) : (
              <p>🔹 Nenhuma pauta disponível.</p>
            )}
          </ul>
        </div>
      )}
      
            {/* Seção: Ordem do Dia */}
      {exibirOrdem && (
        <div className={styles.pautaGrupo}>
          <h3>Matérias da Ordem do Dia</h3>
          <ul>
            {sessaoOrdem.length > 0 ? (
              sessaoOrdem.map((item) => (
                <li key={item.id}>
                  <strong><FaFileAlt /> {item.titulo || item.pauta?.titulo || "Sem título"}</strong>
                  <div className={styles.dashboardButton}>
                  <button onClick={() => abrirModalPauta(item)} title="Detalhes da Matérias">
                    <FaEye /> Detalhes
                  </button>
                  </div>
                </li>
              ))
            ) : (
              <p>🔹 Nenhuma pauta para votação.</p>
            )}
          </ul>
        </div>
      )}

      {/* Seção: Tribuna */}
      {exibirTribuna && (
        <div className={styles.pautaGrupo}>
          <h3>Tribuna</h3>
          <ul>
            {sessaoTribuna.length > 0 ? (
              sessaoTribuna.map((item) => (
                <li key={item.id}>
                  <strong>{item.nome} | {item.tema} {item.autor} {item.cargo}</strong>

                </li>
              ))
            ) : (
              <p>🔹 Nenhuma fala na tribuna.</p>
            )}
          </ul>
        </div>
      )}
    </section>

    <section hidden className={styles.pautaGrupo}>
  <h2><FaTv className={styles.iconTitle} /> Em discussão</h2>

  {itensPainel.length > 0 ? (
    itensPainel
      .filter(item => ["exibindo", "votacao"].includes(item.status?.toLowerCase()))
      .map((item) => (
        <div key={item.id} className={styles.vereadorEmFala}>
          <h3>{item.status === "votacao" ? "Votando" : "Em discussão"}</h3>

          <div className={styles.vereadorDestaque}>
            <img 
              src={`${process.env.REACT_APP_API}/images/vereadores/${item.vereadorId}_${item.vereadorComPalavra?.replace(/\s+/g, '')}.png`}
              alt={`Foto de ${item.vereadorComPalavra}`}
              onError={(e) => (e.target.src = "/images/placeholder.png")}
            />

            <div className={styles.pautaGrupo}>
              <h2>{item.vereadorComPalavra || "Parlamentar"}</h2>
              <img
                className="partidoImg"
                src={`${process.env.REACT_APP_API}/images/partidos/${item.partido || "default"}.png`}
                alt={`Partido ${item.partido}`}
                onError={(e) => (e.target.src = "/images/partido.png")}
              />
            </div>

            <div className={styles.timerDisplay}>
              {item.inicioFala ? (
                formatarTempo(
                  (Math.floor((Date.now() - item.inicioFala) / 1000) || 0) +
                  (item.tempoUsado || 0)
                )
              ) : (
                "00:00:00"
              )}
            </div>
          </div>

          {/* Se for uma pauta (tem pautaId), exibe título, autor e minuta */}
          {item.pautaId ? (
            <div className={styles.pautaGrupo}>
              <div className={styles.pautaInfo}>
                <p><FaFileAlt /> <strong>{item.titulo}</strong></p>
                <p><FaUser /> {item.autor}</p>
                {item.minuta && <p><FaFileAlt /> {item.minuta}</p>}
              </div>
            </div>
          ) : (
            // Se não for uma pauta, mostra o nome + cargo da pessoa discursando
            <div className={styles.pautaGrupo}>
              <div className={styles.pautaInfo}>
                <p><FaUser /> <strong>{item.titulo}</strong></p>
                <p><FaUser /> {item.cargo || "Cargo não informado"}</p>
              </div>
            </div>
          )}
        </div>
      ))
  ) : (
    <p>🔹 Nenhuma matéria em discussão.</p>
  )}
</section>

<Modal
  isOpen={modalAberto}
  onRequestClose={() => setModalAberto(false)}
  className={styles.modal}
  overlayClassName={styles.modalOverlay}
>
  <div className={styles.modalConteudo}>
    <button onClick={() => setModalAberto(false)} className={styles.btnFecharModal}>
      <FaTimes />
    </button>

    <h2 className={styles.titulo}>
      <FaGavel className={styles.iconeTitulo} /> Votação em andamento
    </h2>

    <p className={styles.infoExtra}>
      Este voto será aplicado a todas as {pautaSelecionada?.length || 0} matérias em votação.
    </p>

    {Array.isArray(pautaSelecionada) && pautaSelecionada.map((pauta) => (
      <div key={pauta.id} className={styles.minutaContainer}>
        <h3><FaScroll className={styles.iconeTexto} /> {pauta.titulo}</h3>
        <hr />
      </div>
    ))}

<div className={styles.urnaEletronica}>
  <button
    onClick={() => {
      if (window.confirm("Tem certeza que deseja votar SIM?")) {
        registrarVoto("sim");
      }
    }}
    className={styles.btnSim}
  >
    <FaCheckCircle className={styles.iconeBotao} /> SIM
  </button>

  <button
    onClick={() => {
      if (window.confirm("Tem certeza que deseja votar NÃO?")) {
        registrarVoto("nao");
      }
    }}
    className={styles.btnNao}
  >
    <FaTimesCircle className={styles.iconeBotao} /> NÃO
  </button>

  <button
    onClick={() => {
      if (window.confirm("Tem certeza que deseja se ABSTER?")) {
        registrarVoto("abstencao");
      }
    }}
    className={styles.btnAbstencao}
  >
    <FaBalanceScale className={styles.iconeBotao} /> ABSTER-SE
  </button>
</div>

  </div>
</Modal>
        </div>

           {/* MODAL VER DETALHES */}
           {modalPauta && (
        <ModalPauta pautaId={modalPauta} onClose={fecharModal} />
      )}
                 {/* Modais */}
                 {modalProposicaoOpen && (
        <ModalNovaProposicao 
          closeModal={() => setModalProposicaoOpen(false)}
          onSave={handleSalvarProposicao}  // Agora ele recebe os dados corretamente!
        />
      )}

<ToastContainer/>
</>
    </div>
  );
};

export default Dashboard;
