import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Modal.module.css";
import { FaArrowRight, FaTrash, FaPlusCircle, FaTimes, FaCheckCircle } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";

const ModalConfiguracaoSessao = ({ isOpen, closeModal }) => {
  const navigate = useNavigate();
  const [passo, setPasso] = useState(1);
  const [sessaoId, setSessaoId] = useState(null);
  const [tipoSessao, setTipoSessao] = useState("");
  const [sessaoNumero, setSessaoNumero] = useState("");
  const [dataSessao, setDataSessao] = useState("");
  const [atas, setAtas] = useState([]);
  const [ataSelecionada, setAtaSelecionada] = useState("");
  const [expediente, setExpediente] = useState([]);
  const [materiasRecebidas, setMateriasRecebidas] = useState([]);
  const [pautasVotacao, setPautasVotacao] = useState([]);
  const [vereadores, setVereadores] = useState([]);
  const [tribunaLivre, setTribunaLivre] = useState([]);
  const [novoParticipante, setNovoParticipante] = useState({ nome: "", tema: "" });
  const [cargo, setCargo] = useState("");
  const [modalAberto, setModalAberto] = useState(false);
  const [pautasDisponiveis, setPautasDisponiveis] = useState([]);
  const [pautasAdicionadas, setPautasAdicionadas] = useState([]);

  useEffect(() => {
    if (isOpen) {
      verificarSessaoAtiva();
      buscarAtas();
      buscarMaterias();
      buscarVereadores();
    }
  }, [isOpen]);
  
  const buscarMaterias = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/pautas`);
  
      // Separar matérias conforme o status
      const materiasAnalisadas = response.data.filter(p => p.status === "Aguardando Votação");
      const materiasProtocoladas = response.data.filter(p => p.status === "novo");
  
      setPautasDisponiveis(materiasAnalisadas);  // Para votação
      setMateriasRecebidas(materiasProtocoladas); // Para expediente
  
      if (sessaoId) {
        const responseAdicionadas = await axios.get(`${process.env.REACT_APP_API}/sessao-ordem/${sessaoId}`);
        setPautasAdicionadas(responseAdicionadas.data || []);
  
        const responseExpediente = await axios.get(`${process.env.REACT_APP_API}/sessao-expediente/${sessaoId}`);
        setExpediente(responseExpediente.data || []);
      }
    } catch (error) {
      console.error("Erro ao buscar matérias:", error);
      toast.error("❌ Erro ao buscar matérias.");
    }    
  };

  const verificarSessaoAtiva = async () => {
    try {
      const resposta = await axios.get(`${process.env.REACT_APP_API}/sessoes/sessao-atual`);
  
      if (resposta.data && resposta.data.status !== "encerrada") {
        toast.warning("Você está editando uma Sessão Ativa.");
  
        setSessaoId(resposta.data.id);
        setSessaoNumero(resposta.data.numero);
        setTipoSessao(resposta.data.tipo);
        setDataSessao(resposta.data.data);
        setAtaSelecionada(resposta.data.ataId || "");
  
        // Pula o passo 1 e avança para o próximo
        setPasso(2);
      }
    } catch (error) {
      console.error("Erro ao buscar sessão ativa:", error);
      toast.error("Erro ao verificar sessão ativa.");
    }
  };  

  const criarSessao = async () => {
    if (sessaoId) return; // Evita criar sessão duplicada

    try {
      const novaSessao = await axios.post(`${process.env.REACT_APP_API}/sessoes`, {
        tipo: tipoSessao,
        data: dataSessao,
        ataId: ataSelecionada || null,
        status: "agendada",
      });

      setSessaoId(novaSessao.data.id);
      setSessaoNumero(novaSessao.data.numero);
      setPasso(2); // Avança automaticamente
      toast.success("Sessão criada com sucesso!");
    } catch (error) {
      console.error("Erro ao criar nova sessão:", error);
      toast.error("❌ Erro ao criar nova sessão.");
    }    
  };

  const buscarAtas = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/atas`);
      setAtas(response.data || []);
    } catch (error) {
      toast.error("❌ Erro ao buscar atas.");
    }
  };

  const buscarVereadores = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/usuarios/vereadores`);
      setVereadores(response.data || []);
    } catch (error) {
      toast.error("❌ Erro ao buscar vereadores.");
    }
  };

  const handleAvancar = async () => {
    if (passo === 1) {
      if (!tipoSessao || !dataSessao) {
        alert("Por favor, selecione um tipo e uma data para a sessão.");
        return;
      }
      await criarSessao();
    } else {
      setPasso((prevPasso) => prevPasso + 1);
    }
  };

  const handleVoltar = () => setPasso((prevPasso) => Math.max(1, prevPasso - 1));

  const handleAdicionarTribuna = async (pessoa) => {
    if (!sessaoId) return;

    setTribunaLivre(prev => [...prev, pessoa]);

    try {
      await axios.post(`${process.env.REACT_APP_API}/sessao-tribuna`, {
        sessaoId,
        nome: pessoa.nome,
        tema: pessoa.tema,
        status: "pendente",
        cargo: pessoa.tipo,
        usuario_id: pessoa.usuario_id || null,
      });
      toast.success("Participante adicionado à tribuna.");
    } catch (error) {
      console.error("Erro ao adicionar à tribuna:", error);
      toast.error("❌ Erro ao adicionar à tribuna.");
    }
    
  };

  const handleAdicionarExpediente = async (pauta) => {
    if (!sessaoId) return;

    try {
      await axios.post(`${process.env.REACT_APP_API}/sessao-expediente/adicionar`, {
        sessaoId,
        pautaId: pauta.id,
        titulo: pauta.titulo,
        minuta: pauta.minuta,
        autor: pauta.autor,
        tipo: "leitura_materia"
      });

      setExpediente(prev => [...prev, pauta]);
      setMateriasRecebidas(prev => prev.filter(p => p.id !== pauta.id));
      toast.success("Matéria adicionada ao expediente.");
    } catch (error) {
      console.error("Erro ao adicionar expediente:", error);
      toast.error("❌ Erro ao adicionar expediente.");
    }    
  };

  const handleAdicionarPautaVotacao = async (pauta) => {
    if (!sessaoId) return;
  
    try {
      await axios.post(`${process.env.REACT_APP_API}/sessao-ordem/adicionar`, {
        sessaoId,
        titulo: pauta.titulo,
        minuta: pauta.minuta,
        autor: pauta.autor,
        pautaId: pauta.id,
      });
  
      // Atualiza os estados localmente
      setPautasAdicionadas(prev => [...prev, pauta]);
      setPautasDisponiveis(prev => prev.filter(p => p.id !== pauta.id));
      toast.success("Matéria adicionada à ordem do dia.");
    } catch (error) {
      console.error("Erro ao adicionar pauta para votação:", error);
      toast.error("❌ Erro ao adicionar pauta para votação.");
    }    
  };
  
  const handleRemoverPautaVotacao = async (pautaId) => {
    if (!sessaoId) return;
  
    try {
      await axios.delete(`${process.env.REACT_APP_API}/sessao-ordem/remover/${pautaId}`);
  
      // Mover de volta para disponíveis
      const pautaRemovida = pautasAdicionadas.find(p => p.id === pautaId);
      setPautasDisponiveis(prev => [...prev, pautaRemovida]);
  
      setPautasAdicionadas(prev => prev.filter(p => p.id !== pautaId));
      toast.success("Matéria removida da ordem do dia.");
    } catch (error) {
      console.error("Erro ao remover pauta da votação:", error);
      toast.error("❌ Erro ao remover pauta da votação.");
    }    
  };  

  const handleAdicionarParticipanteAvulso = () => {
    if (novoParticipante.nome.trim() !== "" && novoParticipante.tema.trim() !== "") {
      handleAdicionarTribuna(novoParticipante);
      setNovoParticipante({ nome: "", tema: "" });
      toast.success("Participante avulso adicionado à tribuna.");
    }
  };

  const handleRemoverItem = (id, setLista) => {
    setLista(prev => prev.filter(item => item.id !== id));
  };

  const abrirModal = () => setModalAberto(true);
const fecharModal = () => setModalAberto(false);

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
      <button className={styles.modalClose} onClick={() => closeModal()}>
  <FaTimes />
</button>

        <h2 className={styles.modalTitle}>Sessão {sessaoNumero} | Agendamento</h2>

{/* PASSO 1: CONFIGURAÇÃO DA SESSÃO */}
{passo === 1 && (
  <div className={styles.inputGroup}>
    <div className={styles.formPauta}>
      <div className={styles.seletorContainer}>
      <label>Tipo de Sessão:</label>
        <div 
          className={`${styles.seletorCard} ${tipoSessao === "Ordinária" ? styles.ativo : ""}`} 
          onClick={() => setTipoSessao("Ordinária")}
        >
          Ordinária
        </div>
        <div 
          className={`${styles.seletorCard} ${tipoSessao === "Extraordinária" ? styles.ativo : ""}`} 
          onClick={() => setTipoSessao("Extraordinária")}
        >
          Extraordinária
        </div>
        <div 
          className={`${styles.seletorCard} ${tipoSessao === "Especial" ? styles.ativo : ""}`} 
          onClick={() => setTipoSessao("Especial")}
        >
          Especial
        </div>
        <div 
          className={`${styles.seletorCard} ${tipoSessao === "Solene" ? styles.ativo : ""}`} 
          onClick={() => setTipoSessao("Solene")}
        >
          Solene
        </div>
        <div 
          className={`${styles.seletorCard} ${tipoSessao === "Secreta" ? styles.ativo : ""}`} 
          onClick={() => setTipoSessao("Secreta")}
        >
          Secreta
        </div>
      </div>
      <div className={styles.seletorContainer}>
      <label>Agendamento da Sessão:</label>
      <input type="date" value={dataSessao} onChange={(e) => setDataSessao(e.target.value)} />
      </div>
      <div className={styles.seletorContainer}>
      <label>Vincule uma Ata:</label>
      <select value={ataSelecionada} onChange={(e) => setAtaSelecionada(e.target.value)}>
        <option value="">Vincule uma Ata (opcional)</option>
        {atas.map((ata) => (
          <option key={ata.id} value={ata.id}> Sessão nº {ata.sessaoId} - {ata.dataCriacao}</option>
        ))}
      </select>
</div>
      <div className={styles.modalActions}>
        <button className={styles.btnAvancar} onClick={handleAvancar}>
          <FaArrowRight /> Próximo
        </button>
      </div>
    </div>
  </div>
)}

{passo === 2 && (
  <div>
    <div className={styles.inputGroup}>
      <div className={styles.formPauta}>
        <ul>
          <h3>Selecione matérias para leitura no Expediente:</h3>
          {materiasRecebidas.map(p => (
            <li key={p.id}>
              {p.tipo} - {p.titulo} 
              <button onClick={() => handleAdicionarExpediente(p)}>
                <FaPlusCircle />
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <ul>
          <h3>Matérias já incluídas no Expediente:</h3>
          {expediente.map(p => (
            <li key={p.id}>
              {p.tipo} - {p.titulo}
              <button onClick={() => handleRemoverItem(p.id, setExpediente)}>
                <FaTrash />
              </button>
            </li>
          ))}
        </ul>
    </div>
  </div>
  
  <div className={styles.modalActions}>
    <button className={styles.btnVoltar} onClick={handleVoltar}>Voltar</button>
    <button className={styles.btnAvancar} onClick={handleAvancar}><FaArrowRight /> Próximo</button>
    </div>
  </div>
)}
        
        {passo === 3 && (
  <div>
    <div className={styles.modalCreateSection}>
      
      {/* Lista de matérias disponíveis para seleção */}
      <ul>
        <h3>Selecione matérias para a Pauta (Ordem do dia)</h3>
        {pautasDisponiveis.map(p => (
          <li key={p.id}>
            {p.tipo} - {p.titulo}
            <button onClick={() => handleAdicionarPautaVotacao(p)}>
              <FaPlusCircle />
            </button>
          </li>
        ))}
      </ul>

      {/* Lista de matérias já adicionadas */}
      <ul>
        <h3>Matérias já incluídas na Pauta:</h3>
        {pautasAdicionadas.map(p => (
          <li key={p.id}>
            {p.tipo} - {p.titulo}
            <button onClick={() => handleRemoverPautaVotacao(p.id)}>
              <FaTrash />
            </button>
          </li>
        ))}
      </ul>

    </div>
    <div className={styles.modalActions}>
    <button className={styles.btnVoltar} onClick={handleVoltar}>Voltar</button>
    <button className={styles.btnAvancar} onClick={handleAvancar}><FaArrowRight /> Próximo</button>
    </div>
  </div>
)}
        {/* PASSO 4: TRIBUNA LIVRE */}
        {passo === 4 && (
          <div>
            {/* Seleção de vereadores */}

            <ul>          
              <h3>Tribuna Vereadores:</h3>
            <div  className={styles.modalCreateSection}>
            <div  className={styles.forms}>
            {vereadores
              .filter(v => !tribunaLivre.some(p => p.usuario_id === v.id))
              .map(v => (
              <li key={v.id}>
                {v.nome} 
                <button onClick={() => handleAdicionarTribuna({ nome: v.nome, usuario_id: v.id, tema: "", tipo: v.tipo || "Vereador", tipo: "discursante" })}>
                  <FaPlusCircle />
                </button>
              </li>
            ))}
            </div>
              </div>
            </ul>

            {/* Inscrição Avulsa */}
            <h3>Tribuna Livre:</h3>
            <div  className={styles.modalCreateSection}>
            <div  className={styles.forms}>
            <input 
              type="text" 
              placeholder="Nome" 
              value={novoParticipante.nome} 
              onChange={(e) => setNovoParticipante({ ...novoParticipante, nome: e.target.value })}
            />
            <input 
              type="text" 
              placeholder="Tema" 
              value={novoParticipante.tema} 
              onChange={(e) => setNovoParticipante({ ...novoParticipante, tema: e.target.value })}
            />
            <button onClick={handleAdicionarParticipanteAvulso}>Adicionar</button>
            </div>
            </div>
            {/* Lista de Inscritos */}
            
            <ul>
            <h3>Inscritos na Tribuna:</h3>
            <div  className={styles.modalCreateSection}>
              {tribunaLivre.map(p => (
                <li key={p.nome}>
                  {p.nome} - {p.tema} 
                  <button onClick={() => handleRemoverItem(p.nome, setTribunaLivre)}>
                    <FaTrash />
                  </button>
                </li>
              ))}
              </div>
            </ul>
            <div className={styles.modalActions}>
            <button  className={styles.btnVoltar} onClick={handleVoltar}>Voltar</button>
            <button className={styles.btnAvancar} onClick={handleAvancar}><FaArrowRight /> Próximo</button>
            </div>
          </div>
        )}

{passo === 5 && (
          <div className={styles.formResumo}>
            <h1> Resumo da Sessão</h1>
            <div className={styles.formResumo}>
            <div className={styles.pautaGrupo}>
              <p><strong>Sessão {tipoSessao} nº:</strong> {sessaoNumero}</p>
              <p><strong>Data:</strong> {dataSessao}</p>
            </div>
            <div className={styles.pautaGrupo}>
              <h3>Expediente</h3>
            <ul>
              {expediente.length > 0 ? (
                expediente.map((p, index) => <li key={index}>{p.titulo}</li>)
              ) : (
                <p>Nenhuma matéria adicionada ao expediente.</p>
              )}
            </ul>
          </div>
            <div className={styles.pautaGrupo}>
            <h3>Ordem do Dia</h3>
            <ul>
              {pautasAdicionadas.length > 0 ? (
                pautasAdicionadas.map((p, index) => <li key={index}>{p.titulo}</li>)
              ) : (
                <p>Nenhuma pauta adicionada para votação.</p>
              )}
            </ul>
</div>

<div className={styles.pautaGrupo}>
            <h3>Tribuna Livre</h3>
            <ul>
              {tribunaLivre.length > 0 ? (
                tribunaLivre.map((t, index) => <li key={index}>{t.nome} - {t.tema}</li>)
              ) : (
                <p>Nenhum participante na Tribuna Livre.</p>
              )}
            </ul>
            </div>
            <div className={styles.modalActions}>
            <button className={styles.btnVoltar} onClick={handleVoltar}>Voltar</button>
            <button
  className={styles.btnSalvar}
  onClick={() => {
    toast.success("Sessão agendada com sucesso!");
    fecharModal();
    setTimeout(() => {
      window.location.href = "/painel-controle";
    }, 1000); // Aguarda o toast aparecer
  }}
>
  <FaCheckCircle /> Concluir
</button>
            </div>
          </div>
          </div>
           )}
      </div>
      <ToastContainer/>
    </div>
  );
};

export default ModalConfiguracaoSessao;
