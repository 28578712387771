import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Modal.module.css";
import { FaTimes, FaSave, FaBan, FaCheckCircle, FaUserCheck, FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModalPresenca = ({ sessaoId, closeModal }) => {
  const [vereadores, setVereadores] = useState([]);
  const [presencas, setPresencas] = useState({});
  const [loading, setLoading] = useState(true);
  const [vereadorSelecionado, setVereadorSelecionado] = useState(null);
  const [mostrarModalMotivo, setMostrarModalMotivo] = useState(false);


  useEffect(() => {
    if (!sessaoId) {
      console.error("⚠️ Erro: 'sessaoId' está indefinido!");
      return;
    }

    console.log(`🔍 Buscando presenças para sessão ID: ${sessaoId}`);

    axios.get(`${process.env.REACT_APP_API}/usuarios/vereadores`)
      .then(response => {
        if (Array.isArray(response.data)) {
          setVereadores(response.data);
          carregarPresencas(response.data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error("Erro ao carregar vereadores:", error);
        setLoading(false);
      });
  }, [sessaoId]);

  const carregarPresencas = async (vereadores) => {
    if (!sessaoId) return;

    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/presencas/sessao/${sessaoId}`);
      console.log("Presenças carregadas:", response.data);

      const presencasRegistradas = response.data.reduce((acc, presenca) => {
        acc[presenca.usuario.id] = presenca.presente === 1 ? "presente" : "ausente"; // 🔥 Corrigindo `usuario.id`
        return acc;
      }, {});

      setPresencas(prev => ({
        ...prev,
        ...vereadores.reduce((acc, v) => {
          acc[v.id] = presencasRegistradas[v.id] || "nao_registrado";
          return acc;
        }, {})
      }));
    } catch (error) {
      console.error(`Erro ao carregar presenças da sessão ${sessaoId}:`, error);
    }
  };

  const registrarPresenca = (usuarioId, presente) => {
    if (!sessaoId) {
      toast.error("Erro: Sessão inválida!");
      return;
    }
  
    let motivo = null;
  
    if (!presente) {
      motivo = prompt("Informe o motivo da ausência:");
  
      if (!motivo) {
        toast.warn("⚠️ Ausência cancelada. Motivo é obrigatório.");
        return;
      }
    }
  
    const status = presente ? "presente" : "ausente";
  
    axios.post(`${process.env.REACT_APP_API}/presencas`, { presente, sessaoId, usuarioId, motivo })
      .then(() => {
        setPresencas(prev => ({ ...prev, [usuarioId]: status }));
        toast.success(`Presença ${presente ? "confirmada" : "registrada como ausente"}!`);
      })
      .catch(error => {
        console.error("Erro ao registrar presença:", error);
        toast.error("Erro ao registrar presença.");
      });
  };

  const motivosAusencia = [
    "Compromisso externo oficial",
    "Problema de saúde",
    "Viagem previamente comunicada",
    "Justificativa protocolada",
    "Ausência injustificada"
  ];
  
  const confirmarAusencia = (motivoSelecionado) => {
    if (!sessaoId || !vereadorSelecionado) return;
  
    axios.post(`${process.env.REACT_APP_API}/presencas`, {
      presente: 0,
      sessaoId,
      usuarioId: vereadorSelecionado.id,
      motivo: motivoSelecionado,
    })
      .then(() => {
        setPresencas(prev => ({ ...prev, [vereadorSelecionado.id]: "ausente" }));
        toast.success(`Ausência registrada com motivo: "${motivoSelecionado}"`);
        setMostrarModalMotivo(false);
        setVereadorSelecionado(null);
      })
      .catch(error => {
        console.error("Erro ao registrar ausência:", error);
        toast.error("Erro ao registrar ausência.");
      });
  };
  

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <button className={styles.modalClose} onClick={closeModal}>
          <FaTimes />
        </button>
        <h2><FaUserCheck /> Registrar Presença na Sessão</h2>

        {loading ? (
          <p>Carregando Sessões...</p>
        ) : (
          <ul className={styles.listaVereadores}>
            {vereadores.length > 0 ? (
              vereadores.map(vereador => (
                <li key={vereador.id} className={styles.vereadorItem}>
                  <span>{vereador.nome}</span>
                  {presencas[vereador.id] === "presente" ? (
                    <FaCheckCircle className={styles.presenteIcon} />
                  ) : presencas[vereador.id] === "ausente" ? (
                    <FaTimesCircle className={styles.ausenteIcon} />
                  ) : null}
                  {presencas[vereador.id] === "nao_registrado" && (
                    <>
                    <div className={styles.modalActionsPresenca}>
                      <button 
                        className={styles.btnSim} 
                        onClick={() => registrarPresenca(vereador.id, 1)}
                      >
                        Presente
                      </button>
                      <button 
                      className={styles.btnNao} 
                      onClick={() => {
                        setVereadorSelecionado(vereador);
                        setMostrarModalMotivo(true);
                      }}
                    >
                      Ausente
                    </button>
                    </div>
                    </>
                  )}
                </li>
              ))
            ) : (
              <p>Nenhuma Sessão disponível</p>
            )}
          </ul>
        )}

        <div className={styles.modalActions}>
                    <button className={styles.btnNao} type="button" onClick={closeModal}><FaBan /> Cancelar</button>
                </div>

{mostrarModalMotivo && (
  <div className={styles.modalOverlay}>
    <div className={styles.modalMotivo}>
      <h3>Selecione o motivo da ausência</h3>
      <ul className={styles.listaMotivos}>
        {motivosAusencia.map((motivo, index) => (
          <li key={index}>
            <button onClick={() => confirmarAusencia(motivo)}>{motivo}</button>
          </li>
        ))}
      </ul>
      <div className={styles.modalActions}>
      <button className={styles.btnCancelar} onClick={() => setMostrarModalMotivo(false)}>Cancelar</button>
    </div>
    </div>
    </div>
  
)}

</div>
      </div>

    
  );
};

export default ModalPresenca;
