import React, { useState, useEffect } from "react";
import styles from "./Modal.module.css";
import {
  FaTimes, FaFileAlt, FaUser, FaAlignLeft, FaUpload,
  FaFileSignature, FaBuilding, FaHashtag, FaSave, FaBan
} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModalNovaProposicao = ({ closeModal, onSave }) => {
  const [formData, setFormData] = useState({
    titulo: "",
    numero: "",
    autores: [],
    tipo: "",
    comissaoId: "",
    minuta: "",
    texto_completo: "",
    anexo: null,
  });

  useEffect(() => {
    setLoading(true);
    
    Promise.all([
      fetch(`${process.env.REACT_APP_API}/comissoes`)
        .then(res => res.json())
        .catch(err => {
          console.error("Erro ao buscar comissões:", err);
          return [];
        }),
      
      fetch(`${process.env.REACT_APP_API}/usuarios/vereadores`)
        .then(res => res.json())
        .catch(err => {
          console.error("Erro ao buscar usuários:", err);
          return [];
        })
    ])
    .then(([comissoesData, usuariosData]) => {
      console.log("Comissões recebidas:", comissoesData);
      console.log("Usuários recebidos:", usuariosData);
      
      setComissoes(Array.isArray(comissoesData) ? comissoesData : []);
      setUsuarios(Array.isArray(usuariosData) ? usuariosData.map(user => user.nome) : []);
      setLoading(false);
    })
    .catch(err => {
      console.error("Erro ao buscar dados:", err);
      setLoading(false);
    });
  }, []);

  const [comissoes, setComissoes] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [autorInput, setAutorInput] = useState("");
  const [sugestoes, setSugestoes] = useState([]);

  const usuarioId = localStorage.getItem("usuarioId");

  const [loading, setLoading] = useState(true);
  
  const handleChange = (e) => {
    const { name, value, multiple, options } = e.target;
  
    if (multiple) {
      // Obtém todas as opções selecionadas e armazena como array de números
      const selectedValues = Array.from(options)
        .filter((opt) => opt.selected)
        .map((opt) => Number(opt.value));
  
      setFormData((prev) => ({
        ...prev,
        [name]: selectedValues,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, anexo: file });
    }
  };

  // Atualiza a lista de sugestões conforme o usuário digita
  const handleAutorInputChange = (e) => {
    const valor = e.target.value;
    setAutorInput(valor);
  
    if (valor.length > 0) {
      const filtrados = usuarios.filter((nome) =>
        nome.toLowerCase().includes(valor.toLowerCase())
      );
      setSugestoes(filtrados.slice(0, 10)); // Limita as sugestões a 10
    } else {
      setSugestoes([]);
    }
  };  

  // Ao pressionar Enter, adiciona o nome digitado, seja ele sugerido ou não
  const handleAddAutor = (e) => {
    if (e.key === "Enter" && autorInput.trim()) {
      e.preventDefault();
      const nome = autorInput.trim();

      if (!formData.autores.includes(nome)) {
        setFormData({ ...formData, autores: [...formData.autores, nome] });
      }

      setAutorInput(""); // Limpa o input
      setSugestoes([]); // Esconde as sugestões
    }
  };

  // Quando o usuário clica em uma sugestão, adiciona o nome
  const handleSelecionarAutor = (nome) => {
    if (!formData.autores.includes(nome)) {
      setFormData({ ...formData, autores: [...formData.autores, nome] });
    }

    setAutorInput(""); // Limpa o input
    setSugestoes([]); // Esconde as sugestões
  };

  // Remove um autor da lista
  const handleRemoveAutor = (index) => {
    const novosAutores = [...formData.autores];
    novosAutores.splice(index, 1);
    setFormData({ ...formData, autores: novosAutores });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const usuarioId = Number(localStorage.getItem("usuarioId")); // Converte para número
  
    if (!usuarioId) {
      toast.error("Erro: Usuário não autenticado.");
      return;
    }
  
    const dadosProposicao = {
      usuarioId,
      titulo: formData.titulo,
      numero: formData.numero,
      tipo: formData.tipo,
      comissaoId: Array.isArray(formData.comissaoId) ? formData.comissaoId.join(",") : formData.comissaoId || "",
      minuta: formData.minuta || "", 
      texto_completo: formData.texto_completo || "",
      autores: Array.isArray(formData.autores) ? formData.autores.join(",") : formData.autores || "",
      anexo: formData.anexo || null, 
    };
  
    console.log("📤 Dados que serão enviados ao Config.js:", dadosProposicao);
  
    try {
      await onSave(dadosProposicao); // Agora estamos passando os dados corretamente
      closeModal();
    } catch (error) {
      console.error("Erro ao salvar a proposição:", error);
      toast.error("Erro ao enviar a proposição. Tente novamente.");
    }
  };  

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <button className={styles.modalClose} onClick={closeModal}>
          <FaTimes />
        </button>

        <h2 className={styles.modalTitle}>
          <FaFileAlt /> Nova Matéria
        </h2>

        <form className={styles.formPautaAdd} onSubmit={handleSubmit}>
            {/* Tipo e Comissão */}


            <div className={styles.inputGroup}>
              <label><FaFileSignature /> Tipo</label>
              <select name="tipo" value={formData.tipo} onChange={handleChange} required>
  <option value="">-- Selecione o Tipo --</option>
  <option value="Projeto de Lei">Projeto de Lei</option>
  <option value="Requerimento">Requerimento</option>
  <option value="Moção">Moção</option>
  <option value="Indicação">Indicação</option>
</select>

            </div>
                        {/* Título e Número */}

            <div className={styles.inputGroup}>
              <label><FaFileAlt /> Título</label>
              <input type="text" name="titulo" value={formData.titulo} onChange={handleChange} required />
            </div>


 <div className={styles.inputGroup}>

          <div className={styles.inputGroup}>
          <label><FaUser /> Autores</label>
          <input
              type="text"
              value={autorInput}
              onChange={handleAutorInputChange}
              onKeyDown={handleAddAutor}
              placeholder="Digite um nome para buscar ou adicionar"
            />

            {sugestoes.length > 0 && (
              <ul className={styles.sugestoesLista}>
                {sugestoes.map((nome, index) => (
                  <li key={index} onClick={() => handleSelecionarAutor(nome)}>
                    {nome}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className={styles.autorTag}>
          {Array.isArray(formData.autores) &&
            formData.autores.map((autor, index) => (
              
              <span key={index}>
                {autor}
                <button type="button" onClick={() => handleRemoveAutor(index)} className={styles.removeAutor}>
                  <FaTimes />
                </button>
              </span>
              
            ))}
            </div>
           

          
          {/* Minuta e Texto Completo */}
          <div className={styles.inputGroup}>
          <label><FaAlignLeft /> Minuta (Resumo)</label>
          <textarea name="minuta" value={formData.minuta} onChange={handleChange} />
          </div>
          <div className={styles.inputGroup}>
          <label><FaAlignLeft /> Texto Completo</label>
          <textarea name="texto_completo" value={formData.texto_completo} onChange={handleChange} />
          </div>
          <div className={styles.inputGroup}>
          <label className={styles.fileInput}>  
            <FaUpload /> Anexar Arquivo
            <input type="file" onChange={handleFileChange} hidden />
          </label>
          </div>

          
</div>

<div className={styles.modalActions}>
            <button className={styles.btnSim} type="submit"><FaSave /> Salvar</button>
            <button className={styles.btnNao} type="button" onClick={closeModal}><FaBan /> Cancelar</button>
        </div>
         
</form>


      </div>
    </div>
  );
};

export default ModalNovaProposicao;
