import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./CamaraMunicipal.module.css";
import { FaArrowLeft, FaHome, FaCogs, FaSignOutAlt, FaChalkboardTeacher, FaUserTie } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const CamaraMunicipal = () => {
  const [camara, setCamara] = useState(null);
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/camara-municipal`)
      .then((response) => {
        setCamara(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar informações da Câmara Municipal:", error);
        setLoading(false);
      });

  }, []);

  if (loading) return <p className={styles.loading}>Carregando...</p>;
  if (!camara) return <p className={styles.error}>Erro ao carregar os dados.</p>;

  return (
    <>

      <nav className={styles.dockMenu}>
        <button className={styles.dockBtn} title="Voltar" onClick={() => navigate(-1)}>
          <FaArrowLeft />
        </button>
        <button className={styles.dockBtn} title="Dashboard" onClick={() => navigate("/dashboard")}>
          <FaHome />
        </button>
        <button className={styles.dockBtn} title="Controle da Sessão" onClick={() => navigate("/painel-controle")}>
          <FaChalkboardTeacher />
        </button>
        <button className={styles.dockBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
          <FaCogs />
        </button>
        <button className={styles.dockBtn} title="Logout" onClick={handleLogout}>
          <FaSignOutAlt />
        </button>
      </nav>

      <div className={styles.painelContainer}>
        <header className={styles.dashboardHeader}>
        <h3>Casa Legislativa</h3>
          <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
        </header>
        <div className={styles.container}>
          <div className={styles.leftPanel}>
            <h3>História da Cidade</h3>
            <p>A Câmara Municipal de Lucena, localizada no litoral norte do estado da Paraíba, desempenha um papel fundamental na representação política e no desenvolvimento do município. Criada após a emancipação do município em 1961, possibilitou um espaço legítimo de debate, deliberação e fiscalização.</p>
            <p>Desde então, a Câmara tem sido o elo entre a comunidade e o poder público, buscando soluções para as demandas locais. A ampliação do número de vereadores ao longo dos anos acompanhou o crescimento da cidade e permitiu maior representatividade.</p>
            <p>Os vereadores de Lucena participaram de decisões cruciais para o município nas áreas de infraestrutura, educação, saúde e cultura. Além disso, a Câmara mantém uma relação próxima com a população, incentivando a participação cidadã e fortalecendo a democracia.</p>
            <p>Atualmente, os vereadores eleitos seguem a missão de legislar e fiscalizar com transparência e compromisso com o povo de Lucena.</p>
          </div>
          <div className={styles.rightPanel}>
            <h3>Mesa Diretora</h3>
            {[ 
              { cargo: "Presidente", nome: camara.presidente },
              { cargo: "Vice-Presidente", nome: camara.vice_presidente },
              { cargo: "1º Secretário", nome: camara.primeiro_secretario },
              { cargo: "2º Secretário", nome: camara.segundo_secretario },
            ].map((membro, index) => (
              <div key={index} className={styles.card}>
                <FaUserTie className={styles.icon} />
                <h4>{membro.nome}</h4>
                <p>{membro.cargo}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CamaraMunicipal;
