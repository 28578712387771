import { io } from "socket.io-client";

// Usa o domínio em produção (com HTTPS) e localhost no dev
const socket = io(
  process.env.REACT_APP_SOCKET || window.location.origin,
  {
    path: "/socket.io",
    transports: ["websocket"],
  }
);

export default socket;
