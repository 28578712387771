import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaChalkboardTeacher, FaHome, FaCogs, FaSignOutAlt, FaArrowLeft, FaEye, FaSort, FaTimes, FaBars, FaSearch, FaFileDownload } from "react-icons/fa";
import styles from "./AtasGerenciar.module.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AtasGerenciar = () => {
  const navigate = useNavigate();
  const [atas, setAtas] = useState([]);
  const [modalAta, setModalAta] = useState(null);
  const [filtroSessao, setFiltroSessao] = useState("");
  const [termoBusca, setTermoBusca] = useState("");
  const [ordenacao, setOrdenacao] = useState({ coluna: "criacao", asc: true });


  useEffect(() => {
    const fetchAtas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/atas`);
        setAtas(response.data);
      } catch (error) {
        console.error("Erro ao buscar atas:", error);
      }
    };
    fetchAtas();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const atasFiltradas = atas
    .filter((ata) => (filtroSessao ? ata.sessaoId === Number(filtroSessao) : true))
    .filter((ata) =>
      termoBusca
        ? Object.values(ata).some(
            (valor) =>
              typeof valor === "string" &&
              valor.toLowerCase().includes(termoBusca.toLowerCase())
          )
        : true
    )
    .sort((a, b) => {
      if (a[ordenacao.coluna] < b[ordenacao.coluna]) return ordenacao.asc ? -1 : 1;
      if (a[ordenacao.coluna] > b[ordenacao.coluna]) return ordenacao.asc ? 1 : -1;
      return 0;
    });

  const alternarOrdenacao = (coluna) => {
    setOrdenacao({
      coluna,
      asc: ordenacao.coluna === coluna ? !ordenacao.asc : true,
    });
  };

  const abrirModalAta = (ata) => setModalAta(ata.id);
  const fecharModal = () => setModalAta(null);

  return (
    <div className={styles.painelContainer}>
      {/* CABEÇALHO */}
      <header className={styles.dashboardHeader}>
        <h3>Gerenciamento de Atas</h3>
          <img src="/logo.png" alt="Logo da Câmara" className={styles.logo} />
        </header>

     <nav className={styles.dockMenu}>
       <button className={styles.dockBtn} title="Voltar" onClick={() => navigate(-1)}>
         <FaArrowLeft />
       </button>
       <button className={styles.dockBtn} title="Dashboard" onClick={() => navigate("/dashboard")}>
         <FaHome />
       </button>
       <button className={styles.dockBtn} title="Controle da Sessão" onClick={() => navigate("/painel-controle")}>
         <FaChalkboardTeacher />
       </button>
       <button className={styles.dockBtn} title="Configuração" onClick={() => navigate("/configuracao")}>
         <FaCogs />
       </button>
       <button className={styles.dockBtn} title="Logout" onClick={handleLogout}>
         <FaSignOutAlt />
       </button>
     </nav>

      <div className={styles.contentGerenciar}>
        <div className={styles.pautasContainer}>
          <div className={styles.section}>
            {/* Filtros */}
            <div className={styles.filtrosContainer}>
              <div className={styles.filtroCard}>
                <FaSearch className={styles.filtroIcon} />
                <input
                  type="text"
                  placeholder="Buscar por sessão, presidente ou conteúdo..."
                  value={termoBusca}
                  onChange={(e) => setTermoBusca(e.target.value)}
                />
              </div>
            </div>

            {/* Tabela de atas */}
            <div className={styles.tabelaContainer}>
              <table className={styles.pautaTabela}>
                <thead>
                  <tr>
                    <th onClick={() => alternarOrdenacao("sessaoId")}>
                      Sessão <FaSort />
                    </th>
                    <th onClick={() => alternarOrdenacao("presidente")}>
                      Presidente <FaSort />
                    </th>
                    <th onClick={() => alternarOrdenacao("secretario")}>
                      Secretário <FaSort />
                    </th>
                    <th onClick={() => alternarOrdenacao("criacao")}>
                      Data <FaSort />
                    </th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {atasFiltradas.length > 0 ? (
                    atasFiltradas.map((ata) => (
                      <tr key={ata.id}>
                        <td>{ata.sessaoId}</td>
                        <td>{ata.presidente}</td>
                        <td>{ata.secretario}</td>
                        <td>{ata.criacao}</td>
                        <td className={styles.acoes}>
                          {ata.anexo && (
                            <a
                              href={`${process.env.REACT_APP_API}/uploads/${ata.anexo}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.actionButton}
                              title="Baixar anexo"
                            >
                              <FaFileDownload />
                            </a>
                          )}
                          <button onClick={() => abrirModalAta(ata)} title="Visualizar Ata">
                            <FaEye />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr><td colSpan="5">Nenhuma ata encontrada.</td></tr>
                  )}
                </tbody>
              </table>
            </div>

            <ToastContainer />
          </div>

        </div>
      </div>
    </div>
  );
};

export default AtasGerenciar;
